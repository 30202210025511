import React, { useEffect } from 'react';
import { FaCheckCircle } from "react-icons/fa";

const ConfirmationAlert = ({ message, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(onClose, 4000);
    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div className="confirmation-alert">
        <FaCheckCircle className='confirmation-alert-icon' />
        <div className='confirmation-alert-message'>{message}</div>
        <div className='confirmation-alert-button' onClick={onClose}>Close</div>
    </div>
  );
};

export default ConfirmationAlert;