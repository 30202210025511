import React, { useState } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

function ImageCropper({ image, onCropComplete, aspect }) {
    const [crop, setCrop] = useState({ aspect: aspect, width: 100 });

    const getCroppedImg = (image, crop) => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve) => {
            canvas.toBlob((blob) => {
                resolve(blob);
            }, 'image/jpeg');
        });
    };

    const handleCropComplete = async (crop, pixelCrop) => {
        if (pixelCrop.width && pixelCrop.height) {
            const croppedImage = await getCroppedImg(
                document.getElementById('cropImage'),
                pixelCrop
            );
            onCropComplete(croppedImage);
        }
    };

    return (
        <div className="image-cropper-overlay">
            <div className="image-cropper-container">
                <ReactCrop
                    src={image}
                    crop={crop}
                    onChange={(newCrop) => setCrop(newCrop)}
                    onComplete={handleCropComplete}
                    aspect={aspect}
                >
                    <img id="cropImage" src={image} alt="Crop" />
                </ReactCrop>
                <button onClick={() => handleCropComplete(crop, crop)}>
                    Confirm Crop
                </button>
            </div>
        </div>
    );
}

export default ImageCropper;