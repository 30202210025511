import React, { useEffect, useState } from 'react';
import { supabase } from '../SupabaseClient';
import { Button, Tabs, Tab } from 'react-bootstrap';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { RiFeedbackFill } from "react-icons/ri";
import { TbMessages } from "react-icons/tb";
import NewRequestsTab from '../components/NewRequestsTab';
import EditProfileTab from '../components/EditProfileTab';
import HelpTab from '../components/HelpTab';
import { BsLightning } from "react-icons/bs";
import { LuHelpCircle } from "react-icons/lu";
import { FaRegUser } from "react-icons/fa6";
import { IoMdAlert } from "react-icons/io";
import ConversationsTab from '../components/ConversationsTab';

function CreatorDashboard({ session }) {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [userID, setUserID] = useState('');
    const [newRequestsCount, setNewRequestsCount] = useState(0);
    const [completedRequestsCount, setCompletedRequestsCount] = useState(0);
    const [totalRequestsCount, setTotalRequestsCount] = useState(0);
    const [isFeedbackExpanded, setIsFeedbackExpanded] = useState(false);
    const [feedbackInputValue, setFeedbackInputValue] = useState('');
    const [rejectionNote, setRejectionNote] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [activeTab, setActiveTab] = useState('conversations');
    const [loading, setLoading] = useState(true);
    const [athleteProfile, setAthleteProfile] = useState({
        firstName: '',
        lastName: '',
        sport: '',
        position: '',
        achievements: [],
        no_go_topics: "",
        bio: '',
        interests: [],
        instagram: '',
        tiktok: '',
        x: '',
        role: '',
        email: '',
        paused: '',
        profile_pic: null,
        country: '',
        country_code: '',
        stripe_account_id: false,
        earningsTotal: 0,
    });
    const [profilePicUrl, setProfilePicUrl] = useState('');
    const [isEarningsUpdated, setIsEarningsUpdated] = useState(false);

    const handleFeedbackExpand = () => {
        setIsFeedbackExpanded(true);
    };

    const handleFeedbackClose = () => {
        setIsFeedbackExpanded(false);
        setFeedbackInputValue('');
    };

    const handleFeedbackSubmit = async () => {
        try {
            const { data, error } = await supabase
                .from('user_feedback')
                .insert([
                    { content: feedbackInputValue, username: username, userID: userID }
                ]);

            if (error) {
                throw error;
            }
            setIsFeedbackExpanded(false);
            setFeedbackInputValue('');
        } catch (error) {
            console.error('Error submitting feedback:', error.message);
        }
    };

    // useEffect(() => {
    //     setLoading(true);
    //     const fetchSession = async () => {
    //         const { data: { session } } = await supabase.auth.getSession();
    //         setSession(session);
    //     };
    //     fetchSession();
    // }, []);

    // useEffect(() => {
    //     const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
    //         setSession(session);
    //     });
    //     return () => subscription.unsubscribe();
    // }, []);

    const updateEarnings = async (amount) => {
        const newEarningsTotal = athleteProfile.earningsTotal + amount;

        // Update the local state
        setAthleteProfile((prevProfile) => ({
            ...prevProfile,
            earningsTotal: newEarningsTotal,
        }));

        // Update the earnings in the Supabase database
        try {
            const { error } = await supabase
                .from('users')
                .update({ earningsTotal: newEarningsTotal })
                .eq('uuid', userID);

            if (error) {
                console.error('Error updating earnings in Supabase:', error);
            }
        } catch (error) {
            console.error('Error updating earnings:', error);
        }

        setIsEarningsUpdated(true);
        setTimeout(() => setIsEarningsUpdated(false), 500); // Reset animation state after 1 second
    };

    // useEffect(() => {
    //     if (userID) {
    //         fetchRequestCounts(userID);
    //     }
    // }, [userID]);

    // Fetch profile and set userID
    useEffect(() => {
        if (session) {
            fetchAthleteProfile();
        }
        if (userID && userID !== '') {
            fetchRequestCounts(userID);
            setLoading(false);
        }
    }, [session, userID]);

    const fetchAthleteProfile = async () => {
        const { data: user, error: authError } = await supabase.auth.getUser();
        if (authError || !user) {
            console.error('Error fetching user:', authError);
            return;
        }
        const userID = user.user.id;
        const { data, error } = await supabase
            .from('users')
            .select('username, firstName, lastName, sport, position, achievements, bio, interests, instagram, tiktok, x, profile_pic, no_go_topics, role, email, earningsTotal, paused, country, city, stripe_account_id, stripe_onboarded, country_code')
            .eq('uuid', userID)
            .single();
        if (error) {
            console.error('Error fetching athlete profile:', error);
        } else {
            setUsername(data.username);
            setAthleteProfile(data);
            setUserID(userID);  // This will trigger the fetchRequestCounts
        }
    };

    const fetchRequestCounts = async (userID) => {
        try {
            const { count: newCount, error: newError } = await supabase
                .from('messages')
                .select('*', { count: 'exact' })
                .eq('receiver_id', userID)
                .eq('q_or_a', 'question')
                .eq('status', 'approved');

            if (newError) throw newError;

            const { count: completedCount, error: completedError } = await supabase
                .from('messages')
                .select('*', { count: 'exact' })
                .eq('receiver_id', userID)
                .eq('q_or_a', 'question')
                .eq('status', 'completed');

            if (completedError) throw completedError;

            const { count: totalCount, error: totalError } = await supabase
                .from('messages')
                .select('*', { count: 'exact' })
                .eq('q_or_a', 'question')
                .eq('receiver_id', userID);

            if (totalError) throw totalError;

            setNewRequestsCount(newCount || 0);
            setCompletedRequestsCount(completedCount || 0);
            setTotalRequestsCount(totalCount || 0);
        } catch (error) {
            console.error('Error fetching request counts:', error);
        }
    };

    const fetchDefaultProfilePic = async () => {
        const { data, error } = await supabase
            .storage
            .from('userMedia')
            .getPublicUrl('default/profilePicture/default_profilepic.png'); // Adjust the file name as needed

        if (error) {
            console.error('Error fetching default profile picture:', error.message);
            return null;
        }
        return data.publicUrl;
    };

    // useEffect(() => {
    //     if (session) {
    //         fetchAthleteProfile();
    //         fetchRequestCounts(userID);
    //     }
    // }, [session]);

    useEffect(() => {
        const updateProfilePicUrl = async () => {
            if (athleteProfile.profile_pic) {
                setProfilePicUrl(athleteProfile.profile_pic);
            } else {
                const defaultPicUrl = await fetchDefaultProfilePic();
                setProfilePicUrl(defaultPicUrl);
            }
        };
        updateProfilePicUrl();
    }, [athleteProfile.profile_pic]);

    useEffect(() => {
        const checkStripeOnboardingStatus = async () => {
            // Check if the user is not onboarded
            if (!athleteProfile.stripe_onboarded) {
                try {
                    const token = session?.access_token; // Access the JWT token from the session
                    if (!token) throw new Error('User not authenticated.');
                    // Call your API to check the Stripe account status
                    const { data } = await axios.get('https://infield-backend.vercel.app/check-stripe-status', {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                } catch (error) {
                    console.error('Error checking Stripe onboarding status:', error.message);
                }
            }
        };
        checkStripeOnboardingStatus();
    }, [session]);

    // Add this useEffect to fetch counts when tab changes
    useEffect(() => {
        if (userID) {
            fetchRequestCounts(userID);
        }
    }, [activeTab, userID]); // Add activeTab as a dependency

    // Update the tab selection handler to be more explicit
    const handleTabSelect = (tabKey) => {
        setActiveTab(tabKey);
    };

    // Add this function to pass down to ConversationsTab
    const refreshDashboard = async () => {
        if (userID) {
            await fetchRequestCounts(userID);
            await fetchAthleteProfile(); // This will update earnings
        }
    };

    // if (!session) {
    //     return (
    //         <div className="dashboard-page-format">
    //             <div className="contact-form">
    //                 <div className="contact-title">Sign in</div>
    //                 <Auth
    //                     supabaseClient={supabase}
    //                     appearance={{ theme: ThemeSupa }}
    //                     providers={[]}
    //                     showLinks={false}
    //                     localization={{
    //                         variables: {
    //                             sign_in: {
    //                                 email_label: 'Your email address',
    //                                 password_label: 'Your password',
    //                             },
    //                             forgotten_password: {
    //                                 button_label: 'Forgot password?',
    //                             },
    //                         },
    //                     }}
    //                 />
    //             </div>
    //         </div>
    //     );
    // }
    

    if (loading) {
        return (
            <div className="dashboard-page-format">
                <div className="loader-ring"><div></div><div></div><div></div><div></div></div>
            </div>
        );
    } else {
        return (
            <div className="dashboard-page-format">
                <div className="floating-button ${isFeedbackExpanded ? 'expanded' : ''}" onClick={!isFeedbackExpanded ? handleFeedbackExpand : null}>
                    {!isFeedbackExpanded ? (
                        <div>
                            <div style={{display: "inline-block"}}>Need something?</div>
                            <RiFeedbackFill className='floating-button-icon'/>
                        </div>
                    ) : (
                        <div className="floating-button-expanded-content">
                            <div className="floating-button-title">Contact our team</div>
                            <textarea
                                value={feedbackInputValue}
                                onChange={(e) => setFeedbackInputValue(e.target.value)}
                                placeholder='Let us know about any issues or suggestions you have.'
                            />
                            <button onClick={handleFeedbackSubmit}>Submit</button>
                            <button onClick={handleFeedbackClose}>Close</button>
                        </div>
                    )}
                </div>
                <div className="dashboard-form">
                <div className='dashboard-header-container'>
        <div className='dashboard-header-profile'>
            <img className="dashboard-profile-picture" src={profilePicUrl} alt="Profile" />
            <div className='dashboard-name'>{athleteProfile.firstName} {athleteProfile.lastName}</div>
            <Button className='dashboard-profile-button' href={`/athletes/${athleteProfile.username}`}>View profile</Button>
        </div>
        <div className='dashboard-header-stats'>
            <div className='dashboard-header-stat'>
                <div className='dashboard-header-stat-number'>{newRequestsCount}</div>
                <div>New requests</div>
            </div>
            <div className='dashboard-header-stat'>
                <div className='dashboard-header-stat-number'>{completedRequestsCount}</div>
                <div>Fulfilled requests</div>
            </div>
            <div className={`dashboard-header-stat ${isEarningsUpdated ? 'earnings-updated' : ''}`}>
                <div className='dashboard-header-stat-number'>
                    $ {athleteProfile.earningsTotal.toFixed(2)}
                </div>
                <div>Total earnings</div>
            </div>
        </div>
        {/* {athleteProfile.stripe_onboarded == false && <>
            <div className="roster-banner" style={{backgroundColor: "#FFE799", marginTop: "10px", marginBottom: "5px"}}>
              <div><IoMdAlert className='roster-icon' style={{marginRight: "10px"}}/></div>
              <div className='roster-title' style={{fontSize:"min(3vw,var(--textSizeBody))"}}>To begin receiving requests, follow the guide in the "Help" tab to set up your payment information.</div>
            </div>
        </>} */}
    </div>
    <Tabs activeKey={activeTab} onSelect={handleTabSelect} className='dashboard-tab-title'>
        {/* <Tab eventKey="inbox"
            title={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <BsLightning style={{marginRight: '8px' }}/>
                    <span className="tab-text">Requests</span>
                </div>
            }
        >
            <NewRequestsTab
                session={session}
                athleteProfile={athleteProfile}
                updateEarnings={updateEarnings}
            />
        </Tab> */}
        <Tab eventKey="conversations"
            title={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <TbMessages style={{marginRight: '8px' }}/>
                    <span className="tab-text">Conversations</span>
                </div>
            }
        >
            <ConversationsTab
                session={session}
                onUpdateDashboard={refreshDashboard}
                updateEarnings={updateEarnings}
            />
        </Tab>
        <Tab eventKey="profile"
            title={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FaRegUser style={{marginRight: '8px' }}/>
                    <span className="tab-text">Profile</span>
                </div>
            }
        >
            <EditProfileTab
                session={session}
                athleteProfile={athleteProfile}
                setAthleteProfile={setAthleteProfile}
                userID={userID}
            />
        </Tab>
        <Tab eventKey="help"
            title={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <LuHelpCircle style={{marginRight: '8px' }}/>
                    <span className="tab-text">Help</span>
                </div>
            }
        >
            <HelpTab athleteProfile={athleteProfile} />
        </Tab>
    </Tabs>
</div>
</div>
);
}
}

export default CreatorDashboard;
