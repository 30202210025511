import React, { useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import ReCAPTCHA from "react-google-recaptcha";
import { supabase } from '../SupabaseClient';

function ContactForm() {
  
    // Functions that change the contact page upon submission
    const form = useRef();
    const [submitted, setSubmitted] = useState(false);

    // Functions to adjust message box height dynamically
    const [message, setMessage] = useState('');
    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };
    const calculateRowCount = () => {
        const rowCount = (message.match(/\n/g) || []).length + 1;
        return rowCount < 3 ? 3 : rowCount;
    };

    // State to store the CAPTCHA token
    const [captchaToken, setCaptchaToken] = useState(null);

    // Function to handle CAPTCHA change
    const handleCaptchaChange = (token) => {
        setCaptchaToken(token);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if CAPTCHA is completed
        if (!captchaToken) {
            alert("Please complete the CAPTCHA");
            return;
        }

        const formElements = document.querySelector("form");
        const formData = new FormData(formElements);
        const firstName = formData.get("First Name");
        const lastName = formData.get("Last Name");
        const email = formData.get("Email");
        const subject = formData.get("Title");
        const content = formData.get("Message");

        // Insert data into Supabase table
        const { error } = await supabase
            .from('contact_form')
            .insert([
                { first_name: firstName, last_name: lastName, email: email, subject: subject, content: content }
            ]);

        if (error) {
            console.error('Error!', error.message);
        } else {
            setSubmitted(true);
        }
    };

    
  return (
    <div className='contact-page-format'>
        {!submitted && (
            <form className='contact-form' ref={form} onSubmit={handleSubmit}>
                <div className='contact-title'>Let's get in touch</div>
                <hr className='contact-line'></hr>
                <div className='contact-description'>Infield is open to discussing partnerships, collaborations, other types of inquiries. Send us a message below 
                and someone from our team will get in touch.</div>
                <div className='contact-redirect'>If you are a new athlete, expert, or agent who is interested in joining Infield, click <a className='contact-redirect-link' href='/join'><b>here</b></a>.</div>
                <div className='contact-info-header'>First name</div>
                <input className='contact-info' name="First Name" type="text" required/>
                <div className='contact-info-header'>Last name</div>
                <input className='contact-info' name="Last Name" type="text" required/>
                <div className='contact-info-header'>Email address</div>
                <input className='contact-info' name="Email" type="email" required/>
                <div className='contact-info-header'>Message subject</div>
                <input className='contact-info' name="Title" type="text" required/>
                <div className='contact-info-header'>How can we help?</div>
                <textarea 
                    className='contact-message'
                    value={message}
                    required
                    spellCheck="true"
                    name="Message"
                    onChange={handleMessageChange}
                    rows={calculateRowCount()}
                />
                <ReCAPTCHA 
                    sitekey="6LeBkigqAAAAAEDBIpSKa5W1cZDkNBdI3GNc9Ztf" 
                    onChange={handleCaptchaChange} 
                />
                <input className='contact-button' type="submit" value="Send" />
            </form>
        )}
        {submitted && (
            <div className='contact-received'>
                <div className='contact-form'>
                    <div className='contact-received-title'>
                        Thank you for your inquiry!
                    </div>
                    <div className='contact-received-text'>
                        Check your email for a response from out team. We look forward to connecting with you.
                    </div>
                    <Button className='contact-back-button' href="/">Exit</Button>
                </div>
            </div>
         )}
    </div>
  );
};

export default ContactForm;