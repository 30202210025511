import React, { useEffect, useState } from 'react';
import { supabase } from '../SupabaseClient';
import { Button, Tabs, Tab } from 'react-bootstrap';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { RiFeedbackFill } from "react-icons/ri";
import { TbMessages } from "react-icons/tb";
import NewRequestsTab from '../components/NewRequestsTab';
import EditProfileTab from '../components/EditProfileTab';
import HelpTab from '../components/HelpTab';
import { BsLightning } from "react-icons/bs";
import { LuHelpCircle } from "react-icons/lu";
import { FaRegUser } from "react-icons/fa6";
import { IoMdAlert } from "react-icons/io";
import ConversationsTab from '../components/ConversationsTab';
import CreatorDashboard from '../components/CreatorDashboard';
import BasicDashboard from '../components/BasicDashboard';

function DashboardPage() {
    const [session, setSession] = useState(null);
    const [userRole, setUserRole] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchSession = async () => {
            const { data: { session } } = await supabase.auth.getSession();
            setSession(session);
            if (session) {
                const { data, error } = await supabase
                    .from('users')
                    .select('role')
                    .eq('uuid', session.user.id)
                    .single();
                
                if (!error && data) {
                    setUserRole(data.role);
                }
            }
            setLoading(false);
        };
        fetchSession();
    }, []);

    useEffect(() => {
        const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
            setSession(session);
        });
        return () => subscription.unsubscribe();
    }, []);

    if (loading) {
        return (
            <div className="dashboard-page-format">
                <div className="loader-ring"><div></div><div></div><div></div><div></div></div>
            </div>
        );
    }

    if (!session) {
        return (
            <div className="dashboard-page-format">
                <div className="contact-form">
                    <div className="contact-title">Sign in</div>
                    <Auth
                        supabaseClient={supabase}
                        appearance={{ theme: ThemeSupa }}
                        providers={[]}
                        showLinks={false}
                        localization={{
                            variables: {
                                sign_in: {
                                    email_label: 'Your email address',
                                    password_label: 'Your password',
                                },
                                forgotten_password: {
                                    button_label: 'Forgot password?',
                                },
                            },
                        }}
                    />
                </div>
            </div>
        );
    }

    if (!userRole || (!['admin', 'creator', 'basic'].includes(userRole))) {
        return (
            <div className="dashboard-page-format">
                <div className="contact-form">
                    Your account could not be authenticated at this time. If you have an Infield account, please refresh the page. If not, please create an account in order to access a dashboard.
                </div>
            </div>
        );
    }

    if (userRole === 'basic') {
        return <BasicDashboard session={session} />;
    }

    return <CreatorDashboard session={session} />;
}

export default DashboardPage;
