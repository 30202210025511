import React, { useState, useEffect, useRef } from 'react';
import { supabase } from '../SupabaseClient';
import { Button } from 'react-bootstrap';
import ReactCountryFlag from "react-country-flag";
import { BsLightning } from "react-icons/bs";
import ConfirmationModal from '../components/ConfirmationModal';
import ConfirmationAlert from '../components/ConfirmationAlert';
import SecureVideoPlayer from '../components/SecureVideoPlayer';
import axios from 'axios';
import countries from '../data/countries.json';

function NewRequestsTab({ session, athleteProfile, updateEarnings}) {
    const [submissions, setSubmissions] = useState([]);
    const [selectedSubmission, setSelectedSubmission] = useState(null);
    const [videoFile, setVideoFile] = useState(null);
    const [videoThumbnail, setVideoThumbnail] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalConfig, setModalConfig] = useState({
        title: '',
        message: '',
        confirmText: '',
        cancelText: '',
        onConfirm: null,
        showTextarea: false,
    });
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [rejectionNote, setRejectionNote] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const fileInputRef = useRef(null);

    // Fetching submission data -----------------------------------------------------------------------------------

    const fetchSubmissions = async () => {
        const { data: user, error: authError } = await supabase.auth.getUser();
        const userUID = user.user.id;
        
        // First fetch messages
        const { data: messages, error: messagesError } = await supabase
            .from('messages')
            .select('*')
            .eq('receiver_id', userUID)
            .eq('status', 'approved');

        if (messagesError) {
            console.error('Error fetching messages:', messagesError);
            return;
        }

        // Then fetch corresponding user data
        const senderIds = messages.map(message => message.sender_id);
        const { data: senders, error: sendersError } = await supabase
            .from('users')
            .select('uuid, firstName, lastName, dob, city, country, email')
            .in('uuid', senderIds);

        if (sendersError) {
            console.error('Error fetching senders:', sendersError);
            return;
        }

        // Create a map of sender data for quick lookup
        const senderMap = senders.reduce((acc, sender) => {
            acc[sender.uuid] = sender;
            return acc;
        }, {});

        // Helper function to find country code
        const getCountryCode = (countryName) => {
            // Handle edge case for USA
            if (countryName === "USA" || countryName === "United States") {
                countryName = "United States of America";
            }
            return countries[countryName] || '';
        };

        // Combine message and sender data
        const transformedData = messages.map(message => {
            const sender = senderMap[message.sender_id];
            const age = sender?.dob ? calculateAge(sender.dob) : null;
            return {
                ...message,
                firstName: sender?.firstName,
                lastName: sender?.lastName,
                age: age,
                city: sender?.city,
                email: sender?.email,
                countryName: sender?.country,
                countryValue: getCountryCode(sender?.country),
            };
        });

        setSubmissions(transformedData);
    };

    useEffect(() => {
        if (session) {
            fetchSubmissions();
        }
    }, [session]);

    const feeConverter = (paidAmount) => {
        return Math.floor((paidAmount - (paidAmount * 0.029 + 0.3)) * 100) / 100;
    };

    const handleFileChange = (e) => {
        setVideoFile(e.target.files[0]);
        const file = e.target.files[0];
        if (file) {
            const videoUrl = URL.createObjectURL(file);
            const videoElement = document.createElement('video');
            videoElement.src = videoUrl;
            videoElement.onloadedmetadata = () => {
                if (videoElement.duration > 41) {
                    alert("The selected video is too long. Please select a video that is 40 seconds or less.");
                    clearVideo();
                    return;
                } else {
                    setVideoThumbnail(videoUrl);
                }
            };
        }
    };

    const getVideoUrl = (path) => {
        if (!path) return null;
        const { data: { publicUrl } } = supabase.storage
          .from('conversations')
          .getPublicUrl(path);
        return publicUrl;
      };

    const clearVideo = () => {
        setVideoThumbnail(null);
        setVideoFile(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = null;
        }
    };

    const handleContainerClick = (submission) => {
        if (selectedSubmission && selectedSubmission.id === submission.id) {
            setSelectedSubmission(null);
            clearVideo();
        } else {
            setSelectedSubmission(submission);
            clearVideo();
        }
    };

    const openModal = (config) => {
        setModalConfig(config);
        setIsModalOpen(true);
    };

    const closeAlert = () => {
        setShowAlert(false);
        setAlertMessage('');
    };

    const handleSubmitButton = () => {
        if (selectedSubmission) {
            openModal({
                title: 'Confirm request response',
                message: `Confirm your response to ${selectedSubmission.firstName}\'s request.`,
                confirmText: 'Send response',
                cancelText: 'Cancel',
                placeholderText: '',
                onConfirm: handleSubmit,
                showTextarea: false,
            });
            setRejectionNote(rejectionNote);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!videoFile || !selectedSubmission) return;
        setIsSubmitting(true);
        let videoPath = null;
        try {
            setIsModalOpen(false);
            // Step 1: Upload video to Supabase
            const videoName = `${selectedSubmission.message_id}_response`;
            const fileExtension = videoFile.name.split('.').pop(); // Get the file extension
            videoPath = `${selectedSubmission.conversation_id}/${videoName}.${fileExtension}`;
            const { data: uploadData, error: uploadError } = await supabase.storage
                .from('conversations')
                .upload(videoPath, videoFile, {
                    contentType: videoFile.type
                });

            if (uploadError) {
                await supabase.storage.from('conversations').remove([videoPath]);
                throw new Error('Video upload failed');
            }

            // const { data: urlData } = supabase.storage
            //     .from('conversations')
            //     .getPublicUrl(`responses/${videoName}`);
            
            // Step 2: Prepare request data
            const athleteName = `${athleteProfile.firstName} ${athleteProfile.lastName}`;
            const formData = {
                selectedSubmission: JSON.stringify(selectedSubmission),
                athleteName: JSON.stringify(athleteName),
                videoPath: videoPath
            };

            // Step 3: Call /submit-response
            const response = await axios.post('https://infield-backend.vercel.app/submit-response', formData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                if (selectedSubmission.payment_amount > 0) {
                    // const earningsAfterFees = feeConverter(selectedSubmission.paid);
                    // updateEarnings(earningsAfterFees);
                    const { amountAfterFees } = response.data;
                    updateEarnings(amountAfterFees);
                }
                setAlertMessage('Your response has been sent');
                setShowAlert(true);
                setSubmissions((prev) => prev.filter((submission) => submission.message_id !== selectedSubmission.message_id));
                setSelectedSubmission(null);
            } else {
                throw new Error('Failed to submit response');
            }
        } catch (error) {
            console.error('Error submitting video:', error);
            if (videoPath) {
                try {
                    await supabase.storage
                        .from('conversations')
                        .remove([videoPath]);
                } catch (cleanupError) {
                    console.error('Failed to clean up uploaded video:', cleanupError);
                }
            }
            setAlertMessage('Submission failed. Please try again.');
            setShowAlert(true);
        } finally {
            setIsSubmitting(false);
        }
    };

    const fetchResponseID = async () => {
        const { data, error } = await supabase
            .from('responses')
            .select('*')
            .eq('submission_ID', selectedSubmission.id)
            .order('created_at', { ascending: false })
            .limit(1)
            .single();
        if (error) {
            console.error('Error fetching response ID:', error);
        } else {
            return data.id;
        }
    };

    const handleRejectionButton = () => {
        if (!selectedSubmission) return;
        openModal({
            title: 'Reject Request',
            message: 'Are you sure you want to reject this request? If so, tell our team why so we know not to show you these kinds of requests in the future.',
            confirmText: 'Reject',
            cancelText: 'Cancel',
            placeholderText: 'Reason for rejection (optional)',
            onConfirm: handleReject,
            showTextarea: true,
        });
        setRejectionNote(rejectionNote);
    };

    const handleReject = async (event) => {
        event.preventDefault(event);
        try {
            setIsModalOpen(false);
            const { data, error } = await supabase
                .from('messages')
                .update({ status: "athlete rejected", rejection_note: rejectionNote })
                .eq('message_id', selectedSubmission.message_id);
            if (error) {
                console.error('Error updating request status:', error);
            } else {
                setSubmissions((prev) => prev.filter((submission) => submission.id !== selectedSubmission.id));
                setSelectedSubmission(null);
            }
        } catch (error) {
            console.error('Error rejecting request:', error);
        }
        setAlertMessage('The request has been rejected');
        setShowAlert(true);
    };

    const calculateAge = (dob) => {
        const birthDate = new Date(dob);
        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    };

    useEffect(() => {
        // Check for stored message ID when component mounts
        const storedRequestId = localStorage.getItem('openRequestId');
        if (storedRequestId) {
            // Find and expand the corresponding request
            const requestToOpen = submissions.find(req => req.message_id === storedRequestId);
            if (requestToOpen) {
                setSelectedSubmission(requestToOpen);
            }
            // Clear the stored ID
            localStorage.removeItem('openRequestId');
        }
    }, [submissions]); // Add submissions to dependency array

    window.refreshNewRequests = async () => {
        await fetchSubmissions();
    };

    return (
        <div className="dashboard-stack">
            {showAlert && <ConfirmationAlert message={alertMessage} onClose={closeAlert} />}
            {submissions.length > 0 ? (
                submissions.map((submission) => (
                    <div
                        key={submission.message_id}
                        className={`dashboard-entry ${selectedSubmission && selectedSubmission.message_id === submission.message_id ? 'expanded' : ''}`}
                    >
                        <div className={`dashboard-entry-preview${selectedSubmission && selectedSubmission.message_id === submission.message_id ? `-expanded-${selectedSubmission.text_or_video}` : ''}`} onClick={() => handleContainerClick(submission)}>
                            <div>
                                <div className='dashboard-entry-topline'>
                                    {submission.payment_amount > 0 && (
                                        <div className="dashboard-entry-price">
                                            $ {(Math.floor((submission.payment_amount - (submission.payment_amount * 0.029 + 0.3)) * 100) / 100).toFixed(2)}
                                        </div>
                                    )}
                                    <div className='dashboard-entry-subject'>{submission.subject}</div>
                                </div>
                                <div className='dashboard-entry-preview-info'>
                                    <div className={`dashboard-entry-type-${submission.text_or_video}`}>
                                        {submission.text_or_video === 'video' ? 'Video' : 'Text'}
                                    </div>
                                    <div><div className='text-color-gray'>from </div><b>{submission.firstName} {submission.lastName}</b> <ReactCountryFlag countryCode={submission.countryValue} svg /></div>
                                </div>
                            </div>
                            <div className="dashboard-date">{new Date(submission.created_at + 'Z').toLocaleString('en-US', {
                                year: 'numeric',
                                month: 'short',
                                day: 'numeric',
                            })}</div>
                        </div>
                        {selectedSubmission && selectedSubmission.message_id === submission.message_id && (
                            <div className="dashboard-entry-container">
                                <div className='dashboard-entry-col-details'>
                                    <div className='dashboard-entry-header'>Request details:</div>
                                    <div className='dashboard-entry-generic-text'><b>Requester: </b>{submission.firstName} {submission.lastName}, age {submission.age}</div>
                                    <div className='dashboard-entry-details'><b>From: </b>{submission.city}, {submission.countryName} <ReactCountryFlag countryCode={submission.countryValue} svg /></div>
                                    <div className='dashboard-entry-details'><b>Sent: </b>
                                        {new Date(submission.created_at + 'Z').toLocaleString('en-US', {
                                            month: 'short',
                                            day: 'numeric',
                                            hour: 'numeric',
                                            minute: 'numeric',
                                            hour12: true,
                                            year: 'numeric',
                                        })}
                                    </div>
                                    <div className='dashboard-entry-details'><b>Message:</b></div>
                                    {submission.text_or_video === 'text' && <div className='dashboard-entry-details'>{submission.message}</div>}
                                    {submission.text_or_video === 'video' && (
                                        <SecureVideoPlayer
                                            src={getVideoUrl(selectedSubmission.video_url)}
                                            className='admin-dashboard-video'
                                        />
                                    )}
                                    <div className='dashboard-entry-instructions text-color-gray'>
                                        <b>Note:</b> The dollar amount displayed on requests are an estimate based on expected processing fees, and may vary by a few cents.
                                    </div>
                                </div>
                                <div className='dashboard-entry-col-response'>
                                    <div className='dashboard-entry-header'>Your response:</div>
                                    <div className="request-video-upload">
                                        {videoThumbnail && (
                                            <SecureVideoPlayer
                                                src={videoThumbnail}
                                                className='admin-dashboard-video'
                                            />
                                        )}
                                        <div className='dashboard-button-container'>
                                            {!videoThumbnail ?
                                                (<div>
                                                    <input
                                                        type="file"
                                                        name="video"
                                                        required
                                                        accept="video/*"
                                                        className="dashboard-video-button"
                                                        id="file"
                                                        onChange={handleFileChange}
                                                    />
                                                    <label htmlFor="file">Upload a response</label>
                                                </div>)
                                                : (<div>
                                                    <input
                                                        type="file"
                                                        name="video"
                                                        required
                                                        accept="video/*"
                                                        className="dashboard-video-button-change"
                                                        id="file"
                                                        onChange={handleFileChange}
                                                    />
                                                    <label htmlFor="file">Change video</label>
                                                </div>)
                                            }
                                            {videoFile &&
                                                <div>
                                                    <button onClick={handleSubmitButton} disabled={!videoFile || isSubmitting} className='dashboard-button-submit'>
                                                        {isSubmitting ? (
                                                        <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                                                            Submitting
                                                            <div className="loader-button" style={{ marginLeft: '10px' }}>
                                                            </div>
                                                        </div>
                                                        ) : (
                                                        "Submit response"
                                                        )}
                                                    </button>
                                                    <ConfirmationModal
                                                        isOpen={isModalOpen}
                                                        title={modalConfig.title}
                                                        message={modalConfig.message}
                                                        confirmText={modalConfig.confirmText}
                                                        cancelText={modalConfig.cancelText}
                                                        placeholderText={modalConfig.placeholderText}
                                                        showTextarea={modalConfig.showTextarea}
                                                        onConfirm={modalConfig.onConfirm}
                                                        onCancel={() => setIsModalOpen(false)}
                                                    />
                                                </div>
                                            }
                                            {showAlert && <ConfirmationAlert message={alertMessage} onClose={closeAlert} />}
                                        </div>
                                    </div>
                                    <div className='dashboard-entry-generic-text'><b>Or,</b></div>
                                    <button onClick={handleRejectionButton} disabled={!selectedSubmission} className='dashboard-button-reject'>
                                        Reject request
                                    </button>
                                    <div className='dashboard-entry-instructions text-color-gray'><b>Note:</b> Any requests that haven't been responded to within 3 days of submission will automatically be rejected.</div>
                                    <ConfirmationModal
                                        isOpen={isModalOpen}
                                        title={modalConfig.title}
                                        message={modalConfig.message}
                                        confirmText={modalConfig.confirmText}
                                        cancelText={modalConfig.cancelText}
                                        placeholderText={modalConfig.placeholderText}
                                        showTextarea={modalConfig.showTextarea}
                                        onConfirm={modalConfig.onConfirm}
                                        onCancel={() => setIsModalOpen(false)}
                                        onTextareaChange={(value) => setRejectionNote(value)}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                ))
            ) : (
                <div className="dashboard-no-requests-message">
                    <em>You have no new requests</em>
                </div>
            )}
        </div>
    );
}

export default NewRequestsTab;
