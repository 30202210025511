// React stuff
import React, { useState, useEffect } from 'react';
// Styles
import './styles/main.css';
import './styles/home.css';
import './styles/aboutpage.css';
import './styles/athletelist.css';
import './styles/contactpage.css';
import './styles/components.css';
import './styles/athleteprofilepage.css';
import './styles/requestpage.css';
import './styles/athletedashboard.css';
import './styles/adminpage.css';
import './styles/viewpage.css';
import './styles/rosterpage.css';
import './styles/passwordreset.css';
import './styles/signup.css';
import './styles/legal.css';
import './styles/demopage.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
// Supabase Client
import { supabase } from './SupabaseClient';
// Components on every page
import TopNavbarAnonymous from './components/TopNavbarAnonymous.js'
import TopNavbarAthlete from './components/TopNavbarAthlete.js';
import Footer from './components/Footer.js';
// Pages
import HomePage from './pages/HomePage.js';
import SignIn from './pages/SignIn.js';
import SignUpPage from './pages/SignUpPage.js';
import AthleteJoinPage from './pages/AthleteJoinPage.js';
import ContactPage from './pages/ContactPage.js';
import AthleteListPage from './pages/AthleteListPage.js';
import AthleteProfilePage from './pages/AthleteProfilePage.js';
import RequestPage from './pages/RequestPage.js';
import DashboardPage from './pages/DashboardPage.js';
import AdminPage from './pages/AdminPage.js';
import ViewPage from './pages/ViewPage.js';
import AthletePlaceholderPage from './pages/AthletePlaceholderPage.js';
import PasswordRestPage from './pages/PasswordResetPage.js';
import AboutPage from './pages/AboutPage.js';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import UserSignUpPage from './pages/UserSignUpPage.js';
import DemoPage from './pages/DemoPage.js';
import JoinPage from './pages/JoinPage.js';
// import DemoDashboard from './components/DemoDashboard';
// Browser Router
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  // Check if user is logged in
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      setIsLoggedIn(!!session);
    });
    // Cleanup subscription on unmount
    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  return (
    <div className="App-wrapper">
      {isLoggedIn ? <TopNavbarAthlete /> : <TopNavbarAnonymous />}
      <BrowserRouter>
          <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/signin" element={<SignIn />} />
              <Route path="/join" element={<JoinPage />} />
              <Route path="/fanjoin" element={<UserSignUpPage/>} />
              <Route path="/athletejoin" element={<SignUpPage/>} />
              <Route path="/athletes" element={<AthleteListPage />}/>
              {/* <Route path="/athletes" element={<AthletePlaceholderPage />}/> */}
              <Route path="/waitlist" element={<AthleteJoinPage />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/athletes/:username" element={<AthleteProfilePage />} />
              <Route path="/roster/" element={<AthletePlaceholderPage />} />
              <Route path="/request/:username" element={<RequestPage />} />
              <Route path="/request" element={<RequestPage />} />
              <Route path="/dashboard" element={<DashboardPage />} />
              <Route path="/admin" element={<AdminPage />} />
              <Route path="/view/:message_id" element={<ViewPage />} />
              <Route path="/passwordreset" element={<PasswordRestPage />} />
              <Route path="/privacypolicy" element={<PrivacyPolicy />} />
              <Route path="/termsofservice" element={<TermsOfService />} />
              <Route path="/demo" element={<DemoPage />} />
              {/* <Route path="/demodashboard" element={<DemoDashboard />} /> */}
          </Routes>
          <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
