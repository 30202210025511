import React, { useState } from 'react';
import { LuHelpCircle } from "react-icons/lu";
// assets
import dm_integration from '../dm_integration.png';

function HelpTab({ athleteProfile }) {
    const [expandedFAQ, setExpandedFAQ] = useState(null);

    const handleFAQClick = (index) => {
        setExpandedFAQ(expandedFAQ === index ? null : index);
    };

    const faqs = [
        {
            question: (<div style={{color: "#04AA6D"}}>GUIDE: Setting up payments to receive requests</div>),
            answer: (
                <div>
                    <p>Infield uses Stripe to securely handle purchases and payments so that we don't collect any payment information from you or your fans. Follow the steps below to set up your payment information. If you run into any issues, use the chat button in the bottom right corner to message our team.</p>
                    <p><b>Step 1:</b> Go to your "Profile" tab and select the "Set up payments" button to begin the signup process on their Stripe's website.</p>
                    <p><b>Step 2:</b> Confirm your phone number with the website. When asked for "Type of business", select "Individual"</p>
                    <p><b>Step 3:</b> For "Industry", select "Consulting services". For "Your website", enter "https://www.infieldconnect.co/" for the URL.</p>
                    <p><b>Step 4:</b> Under "Select an account for payouts", choose your preferred bank or institution.</p>
                    <p><b>Step 5:</b> Under "Add public details for customers", enter "INFIELDCONNECT.CO for the "Statement descriptor".</p>
                    <p><b>Step 6:</b> Under "Review and submit", rwait for your information to finish verifying, review your entered information, and then submit the form.</p>
                </div>
            )
        },
        {
            question: (<div style={{color: "#04AA6D"}}>GUIDE: Receiving requests from your Instagram DMs</div>),
            answer: (
                <div>
                    <p>Follow <a style={{fontWeight: "bold", color: "blue"}} target="_blank" href='https://help.instagram.com/561062241952036'>this quick guide</a> to set up an automated message for new DM requests.</p>
                    <p>Copy this text for the Question:</p>
                    <p className='dashboard-faq-copytext'>Hey! I use Infield to answer questions from my followers. Click to get my link!</p>
                    <p>Copy this text for the Automated Response:</p>
                    <p className='dashboard-faq-copytext'>Infield is a platform that makes it easy for athletes to get face-to-face with their followers for questions, requests, or to just say hi! Click this link to try it for free, no signup required 👉 https://infieldconnect.co/request/{athleteProfile.username}</p>
                    <p>Finally, verify that your question is show and available</p>
                    <img style={{width: "min(100%,400px)"}} src={dm_integration} />
                </div>
            )
        },
        {
            question: "How much time do I have to respond to requests?",
            answer: "Once a request enters your dashboard, you have 3 days to respond. If you don't respond within that time, the request will be automatically rejected, and the sender will be refunded."
        },
        {
            question: "Can I view past requests and responses",
            answer: "As of now, you can only view requests that are currently in your dashboard. Past requests and responses are not available."
        }
    ];

    return (
        <div className="dashboard-tab-content">
            <div className="dashboard-faq-title">Frequently Asked Questions</div>
            <div className="dashboard-faq-section">
                {faqs.map((faq, index) => (
                    <div key={index} className="dashboard-faq-item">
                        <div className="dashboard-faq-question" onClick={() => handleFAQClick(index)}>
                            {faq.question}
                        </div>
                        <div className={`dashboard-faq-answer ${expandedFAQ === index ? 'expanded' : ''}`}>
                            {faq.answer}
                        </div>
                    </div>
                ))}
            </div>
            <div style={{marginTop: "20px"}}><b>Need help with something else?</b> Open the feedback messanger in bottom right corner of your screen, and our team will respond as soon as possible.</div>
        </div>
    );
}

export default HelpTab;