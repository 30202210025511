import React, { useState } from 'react';
import { supabase } from '../SupabaseClient';
import { BiShow, BiHide } from 'react-icons/bi'; // Import the icons

function PasswordResetPage() {
    const [email, setEmail] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        if (newPassword !== confirmPassword) {
            setError('New passwords do not match');
            return;
        }

        const { error: signInError } = await supabase.auth.signInWithPassword({
            email: email,
            password: currentPassword,
        });

        if (signInError) {
            setError('Current password is incorrect');
            return;
        }

        const { error: updateError } = await supabase.auth.updateUser({
            password: newPassword,
        });

        if (updateError) {
            setError('Failed to update password. Please ensure that your usernam and current password are correct.');
        } else {
            setSuccess('Password updated successfully!');
        }
    };

    return (
        <div className='contact-page-format'>
            <form className="request-form" onSubmit={handleSubmit}>
                <div className='roster-logo'>Infield</div>
                <div className='passwordreset-title'>Reset your password</div>
                <div className='contact-info-header'>Enter the email address associated with your account</div>
                <input
                    type="email"
                    placeholder="Email"
                    className='contact-info'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <div className='contact-info-header'>Current Password</div>
                <div className='password-input-container'>
                    <input
                        type={showCurrentPassword ? "text" : "password"}
                        placeholder="Current Password"
                        className='passwordreset-input'
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        required
                    />
                    <span className='password-toggle-icon' onClick={() => setShowCurrentPassword(!showCurrentPassword)}>
                        {showCurrentPassword ? <BiHide /> : <BiShow />}
                    </span>
                </div>
                <div className='contact-info-header'>New Password</div>
                <div className='password-input-container'>
                    <input
                        type={showNewPassword ? "text" : "password"}
                        placeholder="New Password"
                        className='passwordreset-input'
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                    />
                    <span className='password-toggle-icon' onClick={() => setShowNewPassword(!showNewPassword)}>
                        {showNewPassword ? <BiHide /> : <BiShow />}
                    </span>
                </div>
                <div className='contact-info-header'>Re-enter New Password</div>
                <div className='password-input-container'>
                    <input
                        type={showConfirmPassword ? "text" : "password"}
                        placeholder="Confirm New Password"
                        className='passwordreset-input'
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                    <span className='password-toggle-icon' onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                        {showConfirmPassword ? <BiHide /> : <BiShow />}
                    </span>
                </div>
                <button className='dashboard-button-submit' type="submit">Reset Password</button>
                {error && <div className="passwordreset-error">{error}</div>}
                {success && <div className="passwordreset-success">{success}</div>}
            </form>
        </div>
    );
}

export default PasswordResetPage;