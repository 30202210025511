import React from 'react';
import { useNavigate } from 'react-router-dom';

// Icons
import basketball_icon from '../basketball_icon.svg';
import exclusive_icon from '../exclusive_icon.svg';

function JoinPage() {
    const navigate = useNavigate();

    return (
        <div className='join-container'>
            <h2 className='join-header'>Create a free account</h2>
            <div className='join-cards-container'>
                <div 
                    className='join-card'
                    style={{ backgroundColor: 'rgba(var(--colorTertiaryRGB), 0.1)' }}
                    onClick={() => navigate('/athletejoin')}
                >
                    <img src={basketball_icon} alt="Athlete icon" className='join-card-icon' />
                    <h3 className='join-card-title' style={{ color: 'var(--colorTertiary)' }}>Infield Athlete</h3>
                    <p className='join-card-subtitle'>Connect with sports fans around the world</p>
                </div>
                <div 
                    className='join-card' 
                    onClick={() => navigate('/fanjoin')}
                >
                    <img src={exclusive_icon} alt="Fan icon" className='join-card-icon' />
                    <h3 className='join-card-title'>Infield Fan</h3>
                    <p className='join-card-subtitle'>Ask questions to your favorite athletes</p>
                </div>
            </div>
        </div>
    );
}

export default JoinPage; 