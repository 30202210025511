import React from 'react';

function TermsOfService() {
  return (
    <div className="legal-container">
      <h1>Terms of Service for Infield Tech, Inc.</h1>
      <p className="last-updated">Effective Date: October 26th, 2024</p>
      <p>Welcome to Infield Tech, Inc. ("Infield," "we," "us," or "our"). These Terms of Service ("Terms") govern your use of our platform, which facilitates personalized video interactions between sports athletes/experts ("Athletes") and fans ("Users"). By accessing or using our services, you agree to comply with these Terms. Please read carefully, as these Terms contain important information about your rights, responsibilities, and legal obligations.</p>
      
      <h2>Table of Contents</h2>
      <ol>
        <li><a href="#section1">Introduction and Acceptance of Terms</a></li>
        <li><a href="#section2">Service Overview</a></li>
        <li><a href="#section3">Content Ownership and Usage Rights</a></li>
        <li><a href="#section4">Fees, Payments, and Refunds</a></li>
        <li><a href="#section5">Marketplace Nature of Platform</a></li>
        <li><a href="#section6">User Accounts and Security</a></li>
        <li><a href="#section7">Intellectual Property and License to Use Platform</a></li>
        <li><a href="#section8">Community Guidelines and Warning System</a></li>
        <li><a href="#section9">Disclaimers and Limitation of Liability</a></li>
        <li><a href="#section10">Indemnity</a></li>
        <li><a href="#section11">Dispute Resolution and Arbitration Agreement</a></li>
        <li><a href="#section12">Governing Law</a></li>
        <li><a href="#section13">General Terms</a></li>
      </ol>

      <section id="section1">
        <h2>1. Introduction and Acceptance of Terms</h2>
        <p><strong>Acceptance:</strong> By using the Infield platform, you agree to comply with these Terms. If you do not agree, you must not use our platform.</p>
      </section>

      <section id="section2">
        <h2>2. Service Overview</h2>
        <p><strong>Fan Requests and Athlete Responses:</strong> Infield allows Users to purchase personalized video responses from Athletes. Payments are authorized upon request and processed only once the Athlete delivers the video to the User's account.</p>
        <p><strong>License for Personal Use Only:</strong> Users receive a non-commercial, personal license to view the video response. Video content cannot be shared, modified, or used for commercial purposes without prior authorization.</p>
      </section>

      <section id="section3">
        <h2>3. Content Ownership and Usage Rights</h2>
        <p><strong>Athlete Ownership:</strong> Athletes retain full ownership of the videos they create.</p>
        <p><strong>Limited License to Users:</strong> Users are granted a limited, non-transferable, personal license to view, share, and enjoy the video content for personal, non-commercial purposes. This license allows sharing content on social media or other personal channels but prohibits any modification or use for commercial purposes.</p>
        <h3>Usage Restrictions:</h3>
        <ul>
          <li><strong>Personal Sharing Permitted:</strong> Users may share videos on personal social media accounts, personal websites, text, and email, provided that no modifications are made to the original content.</li>
          <li><strong>Prohibition on Commercial Use:</strong> Videos cannot be used in advertisements, promotional materials, or any profit-generating activities.</li>
          <li><strong>No Modifications or Edits:</strong> Users are prohibited from altering, modifying, or creating derivative works from the video content.</li>
        </ul>
        <p><strong>Enforcement:</strong> Violating these usage restrictions may result in account termination and legal action.</p>
      </section>

      <section id="section4">
        <h2>4. Fees, Payments, and Refunds</h2>
        <p><strong>Fees and Payments:</strong> All payments are processed through a third-party payment system, Stripe, Inc.. Users authorize a payment hold when submitting a request, and funds are only captured upon delivery of the video response.</p>
        <p><strong>Refund Policy:</strong> Refunds are based solely on delivery. Quality or specific content of the video response is not grounds for a refund. Infield mediates disputes for a current fee of $0, though this fee may change with notice.</p>
        <p><strong>Athlete Payouts:</strong> All payouts are processed through a third-party payment system, Stripe, Inc.. Athletes receive their earnings on a bi-weekly basis if their outstanding balance is greater than $100, minus any applicable processing fees, based on their activity on the platform. If their balance is less than $100, they will receive their earnings on a monthly basis.</p>
      </section>

      <section id="section5">
        <h2>5. Marketplace Nature of Platform</h2>
        <p><strong>Independent Contractors:</strong> Athletes are independent contractors, not employees of Infield. We facilitate connections but do not control or guarantee Athletes' services.</p>
        <p><strong>No Guarantees:</strong> While we strive to maintain quality, we cannot guarantee:</p>
        <ul>
          <li>Video delivery within specific timeframes</li>
          <li>Specific content or quality of responses</li>
          <li>Availability of specific Athletes</li>
        </ul>
      </section>

      <section id="section6">
        <h2>6. User Accounts and Security</h2>
        <p><strong>Account Creation:</strong> Users must provide accurate information when creating accounts and keep credentials secure.</p>
        <p><strong>Account Responsibilities:</strong></p>
        <ul>
          <li>Maintaining confidentiality of login credentials</li>
          <li>Notifying Infield of unauthorized access</li>
          <li>Ensuring account information remains current</li>
        </ul>
        <p><strong>Account Termination:</strong> Infield reserves the right to suspend or terminate accounts for violations of these Terms.</p>
      </section>

      <section id="section7">
        <h2>7. Intellectual Property and License to Use Platform</h2>
        <p><strong>Platform IP:</strong> All platform content, features, and functionality are owned by Infield and protected by intellectual property laws.</p>
        <p><strong>Limited License:</strong> Users receive a limited, revocable license to use the platform for its intended purpose.</p>
        <p><strong>Restrictions:</strong> Users may not:</p>
        <ul>
          <li>Copy or modify platform features</li>
          <li>Reverse engineer the platform</li>
          <li>Use automated systems to access the platform</li>
          <li>Attempt to gain unauthorized access</li>
        </ul>
      </section>

      <section id="section8">
        <h2>8. Community Guidelines and Warning System</h2>
        <p><strong>Prohibited Content:</strong> Users may not submit requests containing:</p>
        <ul>
          <li>Hate speech or discriminatory content</li>
          <li>Explicit or adult content</li>
          <li>Harassment or bullying</li>
          <li>False or misleading information</li>
        </ul>
        <p><strong>Warning System:</strong></p>
        <ul>
          <li>Yellow Card: Users or Athletes who violate guidelines will receive an initial warning.</li>
          <li>Red Card: A second violation will result in permanent removal from the platform.</li>
          <li>Third offense: Account termination</li>
        </ul>
        <p><strong>Content Removal: Infield reserves the right to remove any content or block Users that violate these guidelines, in accordance with the warning system.</strong></p>
      </section>

      <section id="section9">
        <h2>9. Disclaimers and Limitation of Liability</h2>
        <p><strong>Service Disclaimer:</strong> The platform is provided "as is" without warranties of any kind.</p>
        <p><strong>Limitation of Liability:</strong> Infield's liability shall be limited to the amount paid for the specific service in question.</p>
        <p><strong>No Consequential Damages:</strong> We are not liable for indirect, incidental, or consequential damages.</p>
      </section>

      <section id="section10">
        <h2>10. Indemnity</h2>
        <p>Users agree to indemnify and hold harmless Infield, its officers, employees, and agents from any claims, damages, or expenses arising from:</p>
        <ul>
          <li>Violation of these Terms</li>
          <li>Misuse of the platform</li>
          <li>Violation of third-party rights</li>
        </ul>
      </section>

      <section id="section11">
        <h2>11. Dispute Resolution and Arbitration Agreement</h2>
        <p><strong>Mandatory Arbitration:</strong> All disputes shall be resolved through binding arbitration.</p>
        <p><strong>Class Action Waiver:</strong> Users waive rights to participate in class actions.</p>
        <p><strong>Exception:</strong> Small claims court cases and intellectual property disputes may be filed in court.</p>
      </section>

      <section id="section12">
        <h2>12. Governing Law</h2>
        <p>These Terms are governed by the laws of the State of Delaware, without regard to conflicts of law principles.</p>
      </section>

      <section id="section13">
        <h2>13. General Terms</h2>
        <p><strong>Entire Agreement:</strong> These Terms constitute the entire agreement between users and Infield.</p>
        <p><strong>Severability:</strong> If any provision is found invalid, remaining provisions stay in effect.</p>
        <p><strong>No Waiver:</strong> Failure to enforce any right shall not constitute waiver.</p>
        <p><strong>Updates:</strong> Infield may update these Terms with notice to users.</p>
      </section>
    </div>
  );
}

export default TermsOfService;
