import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../SupabaseClient';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import axios from 'axios';
import { Tabs, Tab } from 'react-bootstrap';
import { format } from 'date-fns';
import SecureVideoPlayer from '../components/SecureVideoPlayer';
import ConfirmationModal from '../components/ConfirmationModal'; // Import the ConfirmationModal component
import ConfirmationAlert from '../components/ConfirmationAlert'; // Import ConfirmationAlert

const AdminPage = () => {
  const navigate = useNavigate();
  const [submissions, setSubmissions] = useState([]);
  const [filteredSubmissions, setFilteredSubmissions] = useState([]);
  const [selectedSubmission, setSelectedSubmission] = useState(null);
  const [athleteFilter, setAthleteFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('new');
  const [athletes, setAthletes] = useState([]);
  const [selectedAthlete, setSelectedAthlete] = useState('');
  const [responseVideo, setResponseVideo] = useState(null);
  
  const [messages, setMessages] = useState([]);
  const [filteredMessages, setFilteredMessages] = useState([]);
  const [users, setUsers] = useState([]); // Store all users
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [selectedSender, setSelectedSender] = useState(null);
  const [selectedReceiver, setSelectedReceiver] = useState(null);
  const [receiverFilter, setReceiverFilter] = useState('');

  // Authentication and login
  const [session, setSession] = useState(null);
  useEffect(() => {
      supabase.auth.getSession().then(({ data: { session } }) => {
          setSession(session);
      });
      const {
          data: { subscription },
      } = supabase.auth.onAuthStateChange((_event, session) => {
          setSession(session);
      });

      return () => subscription.unsubscribe();
  }, []);
  // Page redirection if the user isn't an admin----------------------------------------------------------------->
  useEffect(() => {
    const pageRedirect = async () => {
      const { data: user, error: authError } = await supabase.auth.getUser();
      if (authError || !user) {
          console.error('Error fetching user:', authError);
          return;
      }
      const userRole = user.user.user_metadata.role;
      if (userRole === 'creator') {
        navigate('/dashboard');
      } else if (userRole != 'admin') {
        navigate('/');
      }
    };
    if (session) {
      pageRedirect();
    }
    }, [session]);

    
    useEffect(() => {
      const fetchData = async () => {
        // Fetch all users
        const { data: userData, error: userError } = await supabase
          .from('users')
          .select('*')
          .eq('role', 'creator');
        
        if (userError) {
          console.error('Error fetching users:', userError);
        } else {
          setUsers(userData);
        }
    
        // Fetch messages with specific statuses
        const { data: messageData, error: messageError } = await supabase
          .from('messages')
          .select('*')
          .in('status', ['new', 'approved', 'rejected', 'athlete rejected', 'completed'])
          .order('created_at', { ascending: false });
    
        if (messageError) {
          console.error('Error fetching messages:', messageError);
        } else {
          setMessages(messageData);
          setFilteredMessages(messageData);
        }
      };
    
      fetchData();
    }, []);

  // Filter by athlete and submission type
  useEffect(() => {
    const applyFilters = () => {
      let filtered = messages;
      
      if (receiverFilter) {
        filtered = filtered.filter(message => message.receiver_id === receiverFilter);
      }
  
      if (statusFilter) {
        filtered = filtered.filter(message => message.status === statusFilter);
      }
  
  
      setFilteredMessages(filtered);
    };
    applyFilters();
  }, [receiverFilter, statusFilter, messages]);

  // Selected Submission ----------------------------------------------------------------->
  const handleSelectedMessage = async (message) => {
    setSelectedMessage(message);
    console.log(message);
    
    try {
      // Fetch sender details
      const { data: senderData, error: senderError } = await supabase
        .from('users')
        .select('*')
        .eq('uuid', message.sender_id)
        .single();
      
      if (senderError) {
        console.error('Error fetching sender:', senderError);
        setSelectedSender(null);
      } else {
        setSelectedSender(senderData);
      }

      // Fetch receiver details
      const { data: receiverData, error: receiverError } = await supabase
        .from('users')
        .select('*')
        .eq('uuid', message.receiver_id)
        .single();
      
      if (receiverError) {
        console.error('Error fetching receiver:', receiverError);
        setSelectedReceiver(null);
      } else {
        setSelectedReceiver(receiverData);
      }
    } catch (error) {
      console.error('Error in handleSelectedMessage:', error);
      setSelectedSender(null);
      setSelectedReceiver(null);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalConfig, setModalConfig] = useState({
    title: '',
    message: '',
    confirmText: '',
    cancelText: '',
    onConfirm: null,
    showTextarea: false,
    placeholderText: '',
  });
  const [rejectionNote, setRejectionNote] = useState('');
  const [showConfirmationAlert, setShowConfirmationAlert] = useState(false); // State for alert visibility
  const [alertMessage, setAlertMessage] = useState('');

// Function to send rejection email
const sendRejectEmail = async () => {
  const emailData = {
    reply_to: 'info@infieldconnect.co',
    requester_email: selectedSubmission.email,
    requester_name: selectedSubmission.firstName,
    athlete_username: selectedSubmission.athlete,
    rejection_note: rejectionNote,
  };

  try {
    const response = await axios.post('https://infield-backend.vercel.app/reject-request', emailData);
    console.log(response.data);
    setAlertMessage('Request rejected, and the requester has been notified'); // Set alert message
    setShowConfirmationAlert(true); // Show the confirmation alert
  } catch (error) {
    console.error('Failed to send rejection email:', error);
    alert('Failed to send rejection email.');
  }
};


    // Update status of submission
    const handleUpdateStatus = async (newStatus) => {
      // Prevent changes to answers
      if (selectedMessage.q_or_a === 'answer') {
        return;
      }
      if (newStatus === 'rejected') {
        setModalConfig({
          title: 'Reject Submission and Notify Submitter',
          message: 'Give the submitter a reason why the request was rejected',
          confirmText: 'Reject and Send',
          cancelText: 'Cancel',
          placeholderText: 'e.g. this is a no-go topic',
          onConfirm: async () => {
            if (selectedMessage && rejectionNote.trim() !== '') { // Check if rejectionNote has content
              await sendRejectEmail();
              await updateSubmissionStatus(newStatus);
              if (selectedMessage.paid && selectedMessage.paid > 0) {
                await axios.post('https://infield-backend.vercel.app/cancel-payment-intent', {
                  paymentIntentId: selectedMessage.paymentIntentId
                });
              }
            }
            setIsModalOpen(false);
          },
          showTextarea: true,
        });
        setIsModalOpen(true);
        return;
      }
      await updateSubmissionStatus(newStatus);
    };
  
  const updateSubmissionStatus = async (newStatus) => {
    if (selectedMessage) {
      const { data, error } = await supabase
        .from('messages')
        .update({ status: newStatus })
        .eq('message_id', selectedMessage.message_id);
      if (error) {
        console.error('Error updating status:', error);
      } else {
        // Delete submission if rejected
        if (newStatus === 'rejected') {
          if (selectedMessage.text_or_video === 'video') {
            await deleteVideoFromStorage(selectedMessage.video_url);
          }
          const { error: deleteError } = await supabase
            .from('messages')
            .delete()
            .eq('message_id', selectedMessage.message_id);
          if (deleteError) {
            console.error('Error deleting submission:', deleteError);
          }
        }
        // Send notification email to athlete if approved
        if (newStatus == "approved") {
          sendEmail();
        }
        // Clear selection and refresh dashboard
        setSelectedMessage(null); 
        const fetchMessages = async () => {
          const { data, error } = await supabase
            .from('messages')
            .select('*');
          if (error) {
            console.error('Error fetching submissions:', error);
          } else {
            setMessages(data);
            setFilteredMessages(data);
          }
        };
        fetchMessages();
        setRejectionNote('');
      }
    }
  };
  // Function to delete video from storage if rejected
  const deleteVideoFromStorage = async (videoPath) => {
    const { error } = await supabase.storage
      .from('conversations')
      .remove(videoPath);
    if (error) {
      console.error('Error deleting video from storage:', error);
    }
  };

  const getVideoUrl = (path) => {
    if (!path) return null;
    const { data: { publicUrl } } = supabase.storage
      .from('conversations')
      .getPublicUrl(path);
    return publicUrl;
  };

  // Send email to athlete to notify them
  const sendEmail = async () => {
    const emailData = {
      reply_to: 'info@infieldconnect.co',
      athlete_email: selectedReceiver.email,
      athlete_firstName: selectedReceiver.firstName,
      requester_name: `${selectedSender.firstName} ${selectedSender.lastName}`,
    };
  
    try {
      const response = await axios.post('https://infield-backend.vercel.app/new-request', emailData);
      setAlertMessage('Request approved, and email sent to athlete');
      setShowConfirmationAlert(true);
    } catch (error) {
      console.error('Failed to send email:', error);
      alert('Failed to send email.');
    }
  };
  

  const [feedbackEntries, setFeedbackEntries] = useState([]);
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [adminNotes, setAdminNotes] = useState('');
  const [isUpdatingNotes, setIsUpdatingNotes] = useState(false);

  useEffect(() => {
    const fetchFeedbackEntries = async () => {
      const { data, error } = await supabase
        .from('user_feedback')
        .select('*')
        .order('created_at', { ascending: false });
      if (error) {
        console.error('Error fetching feedback entries:', error);
      } else {
        setFeedbackEntries(data);
      }
    };
    fetchFeedbackEntries();
  }, []);

  const handleSelectFeedback = (feedback) => {
    setSelectedFeedback(feedback);
    setAdminNotes(feedback.admin_notes || '');
  };

  // Rename this function to avoid conflict
  const handleUpdateFeedbackStatus = async (newStatus) => {
    if (selectedFeedback) {
      const { error } = await supabase
        .from('user_feedback')
        .update({ status: newStatus })
        .eq('id', selectedFeedback.id);
      if (error) {
        console.error('Error updating status:', error);
      } else {
        setSelectedFeedback({ ...selectedFeedback, status: newStatus });
        setFeedbackEntries(feedbackEntries.map(entry =>
          entry.id === selectedFeedback.id ? { ...entry, status: newStatus } : entry
        ));
      }
    }
  };

  const handleUpdateNotes = async () => {
    if (selectedFeedback) {
      setIsUpdatingNotes(true);
      const { data, error } = await supabase
        .from('user_feedback')
        .update({ admin_notes: adminNotes })
        .eq('id', selectedFeedback.id);
      if (error) {
        console.error('Error updating admin notes:', error);
      } else {
        setSelectedFeedback({ ...selectedFeedback, admin_notes: adminNotes });
        setFeedbackEntries(feedbackEntries.map(entry =>
          entry.id === selectedFeedback.id ? { ...entry, admin_notes: adminNotes } : entry
        ));
      }
      setIsUpdatingNotes(false);
    }
  };

  const [contactRequests, setContactRequests] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [contactNotes, setContactNotes] = useState('');

  useEffect(() => {
    const fetchContactRequests = async () => {
      const { data, error } = await supabase
        .from('contact_form')  // Changed from 'contact_requests' to 'contact_form'
        .select('*')
        .order('created_at', { ascending: false });
      if (error) {
        console.error('Error fetching contact requests:', error);
      } else {
        setContactRequests(data);
      }
    };
    fetchContactRequests();
  }, []);

  const handleSelectContact = (contact) => {
    setSelectedContact(contact);
    setContactNotes(contact.notes || '');
  };

  const handleUpdateContactStatus = async (newStatus) => {
    if (selectedContact) {
      const { error } = await supabase
        .from('contact_form')  // Changed from 'contact_requests' to 'contact_form'
        .update({ status: newStatus })
        .eq('id', selectedContact.id);
      if (error) {
        console.error('Error updating contact status:', error);
      } else {
        setSelectedContact({ ...selectedContact, status: newStatus });
        setContactRequests(contactRequests.map(contact =>
          contact.id === selectedContact.id ? { ...contact, status: newStatus } : contact
        ));
      }
    }
  };

  const handleUpdateContactNotes = async () => {
    if (selectedContact) {
      setIsUpdatingNotes(true);
      const { data, error } = await supabase
        .from('contact_form')
        .update({ notes: contactNotes })
        .eq('id', selectedContact.id);
      if (error) {
        console.error('Error updating contact notes:', error);
      } else {
        setSelectedContact({ ...selectedContact, notes: contactNotes });
        setContactRequests(contactRequests.map(contact =>
          contact.id === selectedContact.id ? { ...contact, notes: contactNotes } : contact
        ));
      }
      setIsUpdatingNotes(false);
    }
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.substr(0, maxLength) + '...';
  };

  const [athleteRequests, setAthleteRequests] = useState([]);
  const [selectedAthleteRequest, setSelectedAthleteRequest] = useState(null);
  const [athleteRequestNotes, setAthleteRequestNotes] = useState('');

  useEffect(() => {
    const fetchAthleteRequests = async () => {
      const { data, error } = await supabase
        .from('athlete_form')
        .select('*')
        .order('created_at', { ascending: false });
      if (error) {
        console.error('Error fetching athlete requests:', error);
      } else {
        setAthleteRequests(data);
      }
    };
    fetchAthleteRequests();
  }, []);

  const handleSelectAthleteRequest = (athlete) => {
    setSelectedAthleteRequest(athlete);
    setAthleteRequestNotes(athlete.notes || '');
  };

  const handleUpdateAthleteRequestStatus = async (newStatus) => {
    if (selectedAthleteRequest) {
      const { error } = await supabase
        .from('athlete_form')
        .update({ status: newStatus })
        .eq('id', selectedAthleteRequest.id);
      if (error) {
        console.error('Error updating athlete request status:', error);
      } else {
        setSelectedAthleteRequest({ ...selectedAthleteRequest, status: newStatus });
        setAthleteRequests(athleteRequests.map(athlete =>
          athlete.id === selectedAthleteRequest.id ? { ...athlete, status: newStatus } : athlete
        ));
      }
    }
  };

  const handleUpdateAthleteRequestNotes = async () => {
    if (selectedAthleteRequest) {
      setIsUpdatingNotes(true);
      const { data, error } = await supabase
        .from('athlete_form')
        .update({ notes: athleteRequestNotes })
        .eq('id', selectedAthleteRequest.id);
      if (error) {
        console.error('Error updating athlete request notes:', error);
      } else {
        setSelectedAthleteRequest({ ...selectedAthleteRequest, notes: athleteRequestNotes });
        setAthleteRequests(athleteRequests.map(athlete =>
          athlete.id === selectedAthleteRequest.id ? { ...athlete, notes: athleteRequestNotes } : athlete
        ));
      }
      setIsUpdatingNotes(false);
    }
  };

  // New useEffect hook to fetch the response video for the selected submission
  useEffect(() => {
    const fetchResponseVideo = async () => {
      if (selectedMessage && selectedMessage.status === 'completed') {
        const { data, error } = await supabase
          .from('messages')
          .select('*')
          .eq('subject', selectedMessage.subject)
          .gt('created_at', selectedMessage.created_at)
          .order('created_at', { ascending: true })
          .limit(1)
          .single();

        if (error) {
          console.error('Error fetching response video:', error);
          setResponseVideo(null);
        } else if (data) {
          const videoUrl = getVideoUrl(data.video_url);
          setResponseVideo(videoUrl);
        } else {
          setResponseVideo(null);
        }
      } else {
        setResponseVideo(null);
      }
    };

    fetchResponseVideo();
  }, [selectedMessage]);

  const [userFeedbackEntries, setUserFeedbackEntries] = useState([]);
  const [selectedUserFeedback, setSelectedUserFeedback] = useState(null);

  // Fetch user feedback entries
  useEffect(() => {
    const fetchUserFeedbackEntries = async () => {
      const { data, error } = await supabase
        .from('requester_feedback')
        .select('*')
        .order('created_at', { ascending: false });
      if (error) {
        console.error('Error fetching user feedback entries:', error);
      } else {
        setUserFeedbackEntries(data);
      }
    };
    fetchUserFeedbackEntries();
  }, []);

  const handleSelectUserFeedback = (feedback) => {
    setSelectedUserFeedback(feedback);
  };

  // New state variables for filters
  const [athleteFeedbackUsernameFilter, setAthleteFeedbackUsernameFilter] = useState('');
  const [athleteFeedbackStatusFilter, setAthleteFeedbackStatusFilter] = useState('');
  const [surveyFeedbackAthleteFilter, setSurveyFeedbackAthleteFilter] = useState('');
  const [surveyFeedbackRatingFilter, setSurveyFeedbackRatingFilter] = useState('');
  const [contactRequestNameFilter, setContactRequestNameFilter] = useState('');
  const [contactRequestStatusFilter, setContactRequestStatusFilter] = useState('');
  const [athleteRequestNameFilter, setAthleteRequestNameFilter] = useState('');
  const [athleteRequestStatusFilter, setAthleteRequestStatusFilter] = useState('');

  // Filter functions for each tab
  const filteredFeedbackEntries = feedbackEntries.filter(entry => 
    entry.username.toLowerCase().includes(athleteFeedbackUsernameFilter.toLowerCase()) &&
    (athleteFeedbackStatusFilter === '' || entry.status === athleteFeedbackStatusFilter)
  );

  const filteredUserFeedbackEntries = userFeedbackEntries.filter(entry =>
    entry.athlete_username.toLowerCase().includes(surveyFeedbackAthleteFilter.toLowerCase()) &&
    (surveyFeedbackRatingFilter === '' || entry.rating.toString() === surveyFeedbackRatingFilter)
  );

  const filteredContactRequests = contactRequests.filter(contact =>
    `${contact.first_name} ${contact.last_name}`.toLowerCase().includes(contactRequestNameFilter.toLowerCase()) &&
    (contactRequestStatusFilter === '' || contact.status === contactRequestStatusFilter)
  );

  const filteredAthleteRequests = athleteRequests.filter(athlete =>
    `${athlete.first_name} ${athlete.last_name}`.toLowerCase().includes(athleteRequestNameFilter.toLowerCase()) &&
    (athleteRequestStatusFilter === '' || athlete.status === athleteRequestStatusFilter)
  );

  if (!session) {
    return (
        <div className="admin-page-format">
            <div className="contact-form">
                <div className="contact-title">Sign in</div>
                <Auth
                    supabaseClient={supabase}
                    appearance={{ theme: ThemeSupa }}
                    providers={[]}
                    showLinks={false}
                    localization={{
                        variables: {
                            sign_in: {
                                email_label: 'Your email address',
                                password_label: 'Your password',
                            },
                            forgotten_password: {
                                button_label: 'Forgot password?',
                            },
                        },
                    }}
                />
            </div>
        </div>
    );
} else {
  return (
    <div className='admin-page-format'>
      {showConfirmationAlert && (
        <ConfirmationAlert
          message={alertMessage}
          onClose={() => setShowConfirmationAlert(false)} // Close the alert
        />
      )}
        <div className="admin-dashboard">
        <Tabs defaultActiveKey="dashboard" id="admin-tabs" className='dashboard-tab-title'>
          <Tab eventKey="dashboard" title="Dashboard">
            <div className='admin-dashboard-text'>
                <label style={{ marginRight: '20px', }}>
                  Filter by Creator:
                <select
                  className="admin-dashboard-filter"
                    value={athleteFilter}
                    onChange={e => setAthleteFilter(e.target.value)}
                >
                  <option value="">All Creators</option>
                  {users.map((user, index) => (
                  <option key={index} value={user.username}>
                      {user.username}
                  </option>
                ))}
                </select>
                </label>
                <label style={{ marginTop: '10px',}}>
                  Filter by Status:
                <select
                  className="admin-dashboard-filter"
                  value={statusFilter}
                  onChange={e => setStatusFilter(e.target.value)}
                >
                  <option value="">All submission</option>
                  <option value="new">New</option>
                  <option value="approved">Approved</option>
                  <option value="rejected">Rejected</option>
                  <option value="athlete rejected">Athlete Rejected</option>
                  <option value="completed">Completed</option>
                </select>
                </label>
            </div>
            <div className='admin-dashboard-table-container'>
              <table className='admin-dashboard-table'>
                <thead>
                  <tr>
                    <th className='admin-dashboard-table-header'>Price Paid</th>
                    <th className='admin-dashboard-table-header'>Receiver</th>
                    <th className='admin-dashboard-table-header'>Status</th>
                    <th className='admin-dashboard-table-header'>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredMessages.map((message) => {
                    const receiver = users.find(u => u.uuid === message.receiver_id);
                    return (
                      <tr
                        key={message.message_id}
                        onClick={() => handleSelectedMessage(message)}
                        style={{ 
                          cursor: 'pointer', 
                          backgroundColor: selectedMessage?.message_id === message.message_id ? '#f0f0f0' : 'white' 
                        }}
                      >
                        <td>$ {message.payment_amount || '0'}</td>
                        <td>{receiver?.username || 'Unknown'}</td>
                        <td>
                          <div className={`admin-dashboard-table-status admin-dashboard-table-status-${message.status.toLowerCase().replace(' ', '-')}`}>
                            {message.status}
                          </div>
                        </td>
                        <td>
                          {new Date(message.created_at + 'Z').toLocaleString('en-US', {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            hour12: true,
                          })} 
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {selectedMessage && (
                <div className='admin-dashboard-request-container'>
                  <div className='admin-dashboard-request-col-admin'>
                    <div className='admin-dashboard-header'>Requester info</div>
                    <div className='admin-dashboard-request-info'>
                      <b>Name:</b> {selectedSender ? `${selectedSender.firstName} ${selectedSender.lastName}` : 'Loading...'}
                    </div>
                    <div className='admin-dashboard-request-info'>
                      <b>Email:</b> {selectedSender ? selectedSender.email : 'Loading...'}
                    </div>
                    <div className='admin-dashboard-request-info'>
                      <b>Subject:</b> {selectedMessage.subject || 'N/A'}
                    </div>
                    <div className='admin-dashboard-request-info'>
                      <b>Price paid:</b> ${selectedMessage.payment_amount || '0'}
                    </div>
                    <div className='admin-dashboard-header'>Requester content</div>
                    {selectedMessage.text_or_video === 'text' && <td>{selectedMessage.message}</td>}
                    {selectedMessage.text_or_video === 'video' && (
                      <SecureVideoPlayer
                        src={getVideoUrl(selectedMessage.video_url)}
                        className='admin-dashboard-video'
                      />
                    )}
                    {selectedMessage.status === 'completed' && responseVideo && (
                      <>
                        <div className='admin-dashboard-header'>Response video</div>
                        <SecureVideoPlayer
                          src={responseVideo}
                          className='admin-dashboard-video'
                        />
                      </>
                    )}
                    <div className='admin-button-container'>
                      {selectedMessage.status === "new" &&
                        <button className="admin-button-approve" onClick={() => handleUpdateStatus('approved')}>Approve</button>
                      }
                      {selectedMessage.status === "new" &&
                        <button className="admin-button-reject" onClick={() => handleUpdateStatus('rejected')}>Reject</button>
                      }
                      {selectedMessage.status === "athlete rejected" &&
                        <div>
                          <div className='admin-dashboard-header'>Athlete's rejection note:</div>
                          <div className='admin-dashboard-request-info'>{selectedMessage.rejection_note}</div>
                        </div>
                      }
                      <select 
                        onChange={(e) => handleUpdateStatus(e.target.value)} 
                        className="admin-status-button"
                        defaultValue="Change Status"
                      >
                        <option disabled>Change Status</option>
                        <option value="new">New</option>
                        <option value="approved">Approved</option>
                        <option value="rejected">Rejected</option>
                        <option value="athlete rejected">Athlete Rejected</option>
                        <option value="completed">Completed</option>
                      </select>
                    </div>
                </div>
                <div className='admin-dashboard-request-col-details'>
                  <div className='admin-dashboard-header'>Athlete's No-go topics</div>
                    <div>
                      <div className='admin-dashboard-request-info'>
                        {selectedReceiver ? selectedReceiver.no_go_topics || 'None specified' : 'Loading...'}
                      </div>
                    </div>
                </div>
              </div>
            )}
            </Tab>
            <Tab eventKey="AthleteFeedback" title="Athlete Feedback">
              <div className='admin-dashboard-text'>
                <label style={{ marginRight: '20px' }}>
                  Filter by Username:
                  <input
                    type="text"
                    className="admin-dashboard-filter"
                    value={athleteFeedbackUsernameFilter}
                    onChange={e => setAthleteFeedbackUsernameFilter(e.target.value)}
                    placeholder="Enter username"
                  />
                </label>
                <label style={{ marginTop: '10px' }}>
                  Filter by Status:
                  <select
                    className="admin-dashboard-filter"
                    value={athleteFeedbackStatusFilter}
                    onChange={e => setAthleteFeedbackStatusFilter(e.target.value)}
                  >
                    <option value="">All statuses</option>
                    <option value="New">New</option>
                    <option value="Awaiting Response">Awaiting Response</option>
                    <option value="Completed">Completed</option>
                  </select>
                </label>
              </div>
              <div className='admin-dashboard-table-container'>
                <table className='admin-dashboard-table'>
                  <thead>
                    <tr>
                      <th className='admin-dashboard-table-header'>Username</th>
                      <th className='admin-dashboard-table-header'>Date</th>
                      <th className='admin-dashboard-table-header'>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredFeedbackEntries.map((entry) => (
                      <tr
                        key={entry.id}
                        onClick={() => handleSelectFeedback(entry)}
                        style={{ cursor: 'pointer', backgroundColor: selectedFeedback?.id === entry.id ? '#f0f0f0' : 'white' }}
                      >
                        <td>{entry.username}</td>
                        <td>{format(new Date(entry.created_at), 'MMMM d, yyyy')}</td>
                        <td>
                          <div className={`admin-dashboard-table-status admin-dashboard-table-status-${(entry.status || '').toLowerCase().replace(' ', '-')}`}>
                            {entry.status || 'N/A'}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {selectedFeedback && (
                <div className='admin-dashboard-request-container'>
                  <div className='admin-dashboard-request-col-details'>
                    <h3>Feedback Details</h3>
                    <p><strong>Username:</strong> {selectedFeedback.username}</p>
                    <p><strong>Date:</strong> {format(new Date(selectedFeedback.created_at), 'MMMM d, yyyy')}</p>
                    <p><strong>Status:</strong> {selectedFeedback.status}</p>
                    <p><strong>Content:</strong> {selectedFeedback.content}</p>
                  </div>
                  <div className='admin-dashboard-request-col-admin'>
                    <h3>Admin Actions</h3>
                    <select 
                      onChange={(e) => handleUpdateFeedbackStatus(e.target.value)}
                      className="admin-status-button"
                      value={selectedFeedback.status || ''}
                    >
                      <option value="">Select Status</option>
                      <option value="New">New</option>
                      <option value="Awaiting Response">Awaiting Response</option>
                      <option value="Completed">Completed</option>
                    </select>
                    <div className='admin-notes-container'>
                      <label htmlFor="admin-notes">Admin notes:</label>
                      <textarea
                        id="admin-notes"
                        value={adminNotes}
                        onChange={(e) => setAdminNotes(e.target.value)}
                        className='admin-notes-textarea'
                      />
                      <button onClick={handleUpdateNotes} className='admin-update-notes-button' disabled={isUpdatingNotes}>
                        {isUpdatingNotes ? (
                          <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                            Updating
                            <div className="loader-button" style={{ marginLeft: '10px' }}></div>
                          </div>
                        ) : (
                          "Update Notes"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </Tab>
            <Tab eventKey="UserFeedback" title="Survey Feedback">
              <div className='admin-dashboard-text'>
                <label style={{ marginRight: '20px' }}>
                  Filter by Athlete Username:
                  <input
                    type="text"
                    className="admin-dashboard-filter"
                    value={surveyFeedbackAthleteFilter}
                    onChange={e => setSurveyFeedbackAthleteFilter(e.target.value)}
                    placeholder="Enter athlete username"
                  />
                </label>
                <label style={{ marginTop: '10px' }}>
                  Filter by Rating:
                  <select
                    className="admin-dashboard-filter"
                    value={surveyFeedbackRatingFilter}
                    onChange={e => setSurveyFeedbackRatingFilter(e.target.value)}
                  >
                    <option value="">All ratings</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>
                </label>
              </div>
              <div className='admin-dashboard-table-container'>
                <table className='admin-dashboard-table'>
                  <thead>
                    <tr>
                      <th className='admin-dashboard-table-header'>ID</th>
                      <th className='admin-dashboard-table-header'>Athlete Username</th>
                      <th className='admin-dashboard-table-header'>Rating</th>
                      <th className='admin-dashboard-table-header'>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredUserFeedbackEntries.map((entry) => (
                      <tr
                        key={entry.id}
                        onClick={() => handleSelectUserFeedback(entry)}
                        style={{ cursor: 'pointer', backgroundColor: selectedUserFeedback?.id === entry.id ? '#f0f0f0' : 'white' }}
                      >
                        <td>{entry.id}</td>
                        <td>{entry.athlete_username}</td>
                        <td>{entry.rating}</td>
                        <td>{format(new Date(entry.created_at), 'MMMM dd, yyyy')}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {selectedUserFeedback && (
                <div className='admin-dashboard-request-container'>
                  <div className='admin-dashboard-request-col-details'>
                    <p><strong>ID:</strong> {selectedUserFeedback.id}</p>
                    <p><strong>Athlete Username:</strong> {selectedUserFeedback.athlete_username}</p>
                    <p><strong>Date:</strong> {format(new Date(selectedUserFeedback.created_at), 'MMMM dd, yyyy')}</p>
                    <p><strong>Request ID:</strong> {selectedUserFeedback.request_id}</p>
                    <p><strong>Response ID:</strong> {selectedUserFeedback.response_id}</p>
                    <p><strong>Rating:</strong> {selectedUserFeedback.rating}</p>
                    <p><strong>Response Feedback:</strong> {selectedUserFeedback.response_feedback}</p>
                    <p><strong>Infield Feedback:</strong> {selectedUserFeedback.infield_feedback}</p>
                  </div>
                </div>
              )}
            </Tab>
            <Tab eventKey="Contact" title="Contact Requests">
              <div className='admin-dashboard-text'>
                <label style={{ marginRight: '20px' }}>
                  Filter by Name:
                  <input
                    type="text"
                    className="admin-dashboard-filter"
                    value={contactRequestNameFilter}
                    onChange={e => setContactRequestNameFilter(e.target.value)}
                    placeholder="Enter name"
                  />
                </label>
                <label style={{ marginTop: '10px' }}>
                  Filter by Status:
                  <select
                    className="admin-dashboard-filter"
                    value={contactRequestStatusFilter}
                    onChange={e => setContactRequestStatusFilter(e.target.value)}
                  >
                    <option value="">All statuses</option>
                    <option value="New">New</option>
                    <option value="In-Progress">In-Progress</option>
                    <option value="Completed">Completed</option>
                  </select>
                </label>
              </div>
              <div className='admin-dashboard-table-container'>
                <table className='admin-dashboard-table'>
                  <thead>
                    <tr>
                      <th className='admin-dashboard-table-header'>Name</th>
                      <th className='admin-dashboard-table-header'>Date</th>
                      <th className='admin-dashboard-table-header'>Subject</th>
                      <th className='admin-dashboard-table-header'>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredContactRequests.map((contact) => (
                      <tr
                        key={contact.id}
                        onClick={() => handleSelectContact(contact)}
                        style={{ cursor: 'pointer', backgroundColor: selectedContact?.id === contact.id ? '#f0f0f0' : 'white' }}
                      >
                        <td>{`${contact.first_name} ${contact.last_name}`}</td>
                        <td>{format(new Date(contact.created_at), 'MMMM d, yyyy')}</td>
                        <td>{truncateText(contact.subject, 20)}</td>
                        <td>
                          <div className={`admin-dashboard-table-status admin-dashboard-table-status-${(contact.status || '').toLowerCase().replace(' ', '-')}`}>
                            {contact.status || 'N/A'}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {selectedContact && (
                <div className='admin-dashboard-request-container'>
                  <div className='admin-dashboard-request-col-details'>
                    <h3>Contact Request Details</h3>
                    <p><strong>Name:</strong> {`${selectedContact.first_name} ${selectedContact.last_name}`}</p>
                    <p><strong>Date:</strong> {format(new Date(selectedContact.created_at), 'MMMM d, yyyy')}</p>
                    <p><strong>Status:</strong> {selectedContact.status || 'N/A'}</p>
                    <p><strong>Email:</strong> {selectedContact.email}</p>
                    <p><strong>Subject:</strong> {selectedContact.subject}</p>
                    <p><strong>Content:</strong> {selectedContact.content}</p>
                  </div>
                  <div className='admin-dashboard-request-col-admin'>
                    <h3>Admin Actions</h3>
                    <select 
                      onChange={(e) => handleUpdateContactStatus(e.target.value)}
                      className="admin-status-button"
                      value={selectedContact.status || ''}
                    >
                      <option value="">Select Status</option>
                      <option value="New">New</option>
                      <option value="In-Progress">In-Progress</option>
                      <option value="Completed">Completed</option>
                    </select>
                    <div className='admin-notes-container'>
                      <label htmlFor="contact-notes">Admin notes:</label>
                      <textarea
                        id="contact-notes"
                        value={contactNotes}
                        onChange={(e) => setContactNotes(e.target.value)}
                        className='admin-notes-textarea'
                      />
                      <button onClick={handleUpdateContactNotes} className='admin-update-notes-button' disabled={isUpdatingNotes}>
                        {isUpdatingNotes ? (
                          <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                            Updating
                            <div className="loader-button" style={{ marginLeft: '10px' }}></div>
                          </div>
                        ) : (
                          "Update Notes"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </Tab>
            <Tab eventKey="AthleteRequest" title="Athlete Requests">
              <div className='admin-dashboard-text'>
                <label style={{ marginRight: '20px' }}>
                  Filter by Name:
                  <input
                    type="text"
                    className="admin-dashboard-filter"
                    value={athleteRequestNameFilter}
                    onChange={e => setAthleteRequestNameFilter(e.target.value)}
                    placeholder="Enter name"
                  />
                </label>
                <label style={{ marginTop: '10px' }}>
                  Filter by Status:
                  <select
                    className="admin-dashboard-filter"
                    value={athleteRequestStatusFilter}
                    onChange={e => setAthleteRequestStatusFilter(e.target.value)}
                  >
                    <option value="">All statuses</option>
                    <option value="New">New</option>
                    <option value="In-Progress">In-Progress</option>
                    <option value="Completed">Completed</option>
                  </select>
                </label>
              </div>
              <div className='admin-dashboard-table-container'>
                <table className='admin-dashboard-table'>
                  <thead>
                    <tr>
                      <th className='admin-dashboard-table-header'>Name</th>
                      <th className='admin-dashboard-table-header'>Date</th>
                      <th className='admin-dashboard-table-header'>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredAthleteRequests.map((athlete) => (
                      <tr
                        key={athlete.id}
                        onClick={() => handleSelectAthleteRequest(athlete)}
                        style={{ cursor: 'pointer', backgroundColor: selectedAthleteRequest?.id === athlete.id ? '#f0f0f0' : 'white' }}
                      >
                        <td>{`${athlete.first_name} ${athlete.last_name}`}</td>
                        <td>{format(new Date(athlete.created_at), 'MMMM d, yyyy')}</td>
                        <td>
                          <div className={`admin-dashboard-table-status admin-dashboard-table-status-${(athlete.status || '').toLowerCase().replace(' ', '-')}`}>
                            {athlete.status || 'N/A'}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {selectedAthleteRequest && (
                <div className='admin-dashboard-request-container'>
                  <div className='admin-dashboard-request-col-details'>
                    <h3>Athlete Request Details</h3>
                    <p><strong>Name:</strong> {`${selectedAthleteRequest.first_name} ${selectedAthleteRequest.last_name}`}</p>
                    <p><strong>Date:</strong> {format(new Date(selectedAthleteRequest.created_at), 'MMMM d, yyyy')}</p>
                    <p><strong>Status:</strong> {selectedAthleteRequest.status || 'N/A'}</p>
                    <p><strong>Email:</strong> {selectedAthleteRequest.email}</p>
                    <p><strong>Location:</strong> {`${selectedAthleteRequest.city}, ${selectedAthleteRequest.country}`}</p>
                    <p><strong>Social:</strong> {`${selectedAthleteRequest.social_platform}: ${selectedAthleteRequest.social_account}`}</p>
                    <p><strong>Sport:</strong> {`${selectedAthleteRequest.sport}, ${selectedAthleteRequest.position}`}</p>
                    <p><strong>Content:</strong> {selectedAthleteRequest.content}</p>
                  </div>
                  <div className='admin-dashboard-request-col-admin'>
                    <h3>Admin Actions</h3>
                    <select 
                      onChange={(e) => handleUpdateAthleteRequestStatus(e.target.value)}
                      className="admin-status-button"
                      value={selectedAthleteRequest.status || ''}
                    >
                      <option value="">Select Status</option>
                      <option value="New">New</option>
                      <option value="In-Progress">In-Progress</option>
                      <option value="Completed">Completed</option>
                    </select>
                    <div className='admin-notes-container'>
                      <label htmlFor="athlete-request-notes">Admin notes:</label>
                      <textarea
                        id="athlete-request-notes"
                        value={athleteRequestNotes}
                        onChange={(e) => setAthleteRequestNotes(e.target.value)}
                        className='admin-notes-textarea'
                      />
                      <button onClick={handleUpdateAthleteRequestNotes} className='admin-update-notes-button' disabled={isUpdatingNotes}>
                        {isUpdatingNotes ? (
                          <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                            Updating
                            <div className="loader-button" style={{ marginLeft: '10px' }}></div>
                          </div>
                        ) : (
                          "Update Notes"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </Tab>
          </Tabs>
            </div>
            <ConfirmationModal
              isOpen={isModalOpen}
              title={modalConfig.title}
              message={modalConfig.message}
              confirmText={modalConfig.confirmText}
              cancelText={modalConfig.cancelText}
              placeholderText={modalConfig.placeholderText}
              showTextarea={modalConfig.showTextarea}
              onConfirm={modalConfig.onConfirm}
              onCancel={() => setIsModalOpen(false)}
              onTextareaChange={(value) => setRejectionNote(value)}
            />
    </div>
  );
};

}

export default AdminPage;
