import React, { useState, useEffect, useMemo } from 'react';
import { Form, Button } from 'react-bootstrap';
import { supabase } from '../SupabaseClient';
import ImageCropper from '../components/ImageCropper';
import axios from 'axios';
import Select from 'react-select';
import countryList from 'react-select-country-list';

function EditProfileTab({ session, athleteProfile, setAthleteProfile, userID }) {
    const [profilePicture, setProfilePicture] = useState(null);
    const [coverPictures, setCoverPictures] = useState([]);

    const [showCropper, setShowCropper] = useState(false);
    const [cropperImage, setCropperImage] = useState(null);
    const [cropperType, setCropperType] = useState(null);
    const [cropperIndex, setCropperIndex] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    // useEffect(() => {
    //     if (athleteProfile.profile_pic) {
    //         setProfilePicture(athleteProfile.profile_pic);
    //     }
    //     if (athleteProfile.cover_pics) {
    //         setCoverPictures(Array.isArray(athleteProfile.cover_pics) ? athleteProfile.cover_pics : []);
    //     }
    // }, [athleteProfile]);

    const handleStripeConnect = async () => {
        try {
            setIsLoading(true);
            const token = session?.access_token; // Access the JWT token from the session
            if (!token) {
                throw new Error('User is not authenticated.');
            }
            const { data } = await axios.post(
                'https://infield-backend.vercel.app/create-stripe-account-link',
                {
                    country_code: athleteProfile.country_code,
                    email: athleteProfile.email
                }, // Body is empty, unless needed
                {
                    headers: {
                        Authorization: `Bearer ${token}` // Send the token in the header
                    }
                }
            );
            window.open(data.url, '_blank');
        } catch (error) {
            console.error('Error connecting to Stripe:', error.message);
        } finally {
            setIsLoading(false);
        }
    };


    const handleProfileChange = (e) => {
        const { name, value } = e.target;
        setAthleteProfile((prevProfile) => ({
            ...prevProfile,
            [name]: value
        }));
    };

    const handlePauseToggle = () => {
        setAthleteProfile(prevProfile => ({
            ...prevProfile,
            paused: !prevProfile.paused
        }));
    };

    const addInterest = () => {
        if (athleteProfile.interests == null) {
            setAthleteProfile(prevProfile => ({
                ...prevProfile,
                interests: [""]
            }));
        }
        setAthleteProfile(prevProfile => ({
            ...prevProfile,
            interests: [...prevProfile.interests, ""]
        }));
    };

    const removeInterest = (index) => {
        setAthleteProfile(prevProfile => ({
            ...prevProfile,
            interests: prevProfile.interests.filter((_, i) => i !== index)
        }));
    };

    const handleInterestChange = (index, value) => {
        setAthleteProfile(prevProfile => {
            const newInterests = [...prevProfile.interests];
            newInterests[index] = value;
            return {
                ...prevProfile,
                interests: newInterests
            };
        });
    };

    const addAchievement = () => {
        if (athleteProfile.achievements == null) {
            setAthleteProfile(prevProfile => ({
                ...prevProfile,
                achievements: [""]
            }));
        }
        setAthleteProfile(prevProfile => ({
            ...prevProfile,
            achievements: [...prevProfile.achievements, ""]
        }));
    };

    const removeAchievement = (index) => {
        setAthleteProfile(prevProfile => ({
            ...prevProfile,
            achievements: prevProfile.achievements.filter((_, i) => i !== index)
        }));
    };

    const handleAchievementChange = (index, value) => {
        setAthleteProfile(prevProfile => {
            const newAchievements = [...prevProfile.achievements];
            newAchievements[index] = value;
            return {
                ...prevProfile,
                achievements: newAchievements
            };
        });
    };

    const handleProfileSubmit = async (event) => {
        event.preventDefault();
        console.log(athleteProfile.paused);
        const confirmation = window.confirm("Are you sure you want to save these changes?");
        if (confirmation) {
            try {
                // let newProfilePicUrl = athleteProfile.profile_pic;
                // if (profilePicture !== athleteProfile.profile_pic) {
                //     newProfilePicUrl = await uploadImageToSupabase(profilePicture, 'profilePicture');
                // }

                // let newCoverPicUrls = [];
                // for (let i = 0; i < coverPictures.length; i++) {
                //     if (coverPictures[i] !== athleteProfile.cover_pics[i]) {
                //         const newUrl = await uploadImageToSupabase(coverPictures[i], 'coverPictures');
                //         newCoverPicUrls.push(newUrl);
                //     } else {
                //         newCoverPicUrls.push(coverPictures[i]);
                //     }
                // }

                // const updatedProfile = {
                //     ...athleteProfile,
                //     profile_pic: newProfilePicUrl,
                //     cover_pics: newCoverPicUrls
                // };

                const { error: updateError } = await supabase
                    .from('users')
                    .update(athleteProfile)
                    .eq('uuid', userID);

                if (updateError) {
                    console.error('Error updating profile:', updateError);
                } else {
                    alert('Profile updated successfully!');
                    setAthleteProfile(athleteProfile);
                }
            } catch (error) {
                console.error('Unexpected error:', error);
            }
        } else {
            alert('Profile update canceled.');
        }
    };

    const handleImageUpload = (event, type, index = null) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setCropperImage(e.target.result);
                setCropperType(type);
                setCropperIndex(index);
                setShowCropper(true);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleCropComplete = async (croppedImage) => {
        setShowCropper(false);
        if (cropperType === 'profile') {
            setProfilePicture(croppedImage);
        } else if (cropperType === 'cover') {
            const newCoverPictures = [...coverPictures];
            newCoverPictures[cropperIndex] = croppedImage;
            setCoverPictures(newCoverPictures);
        }
    };

    const uploadImageToSupabase = async (image, folder) => {
        const username = athleteProfile.username;
        const fileName = `${Date.now()}.jpg`;
        const filePath = `${username}/${folder}/${fileName}`;
        console.log(filePath);

        const { data, error } = await supabase.storage
            .from('userMedia')
            .upload(filePath, image);

        if (error) {
            console.error('Error uploading image:', error);
            return null;
        }

        const { data: { publicUrl } } = supabase.storage
            .from('userMedia')
            .getPublicUrl(filePath);

        return publicUrl;
    };

    // Add country options using react-select-country-list
    const countries = useMemo(() => countryList().getData(), []);

    return (
        <div className="dashboard-tab-content">
            <Form>
                {/* Only show pause requests section for admin/creator roles */}
                {athleteProfile.role !== 'basic' && (
                    <>
                        <div className="dashboard-profile-title">Pause Requests</div>
                        <div className='dashboard-explination'>
                            If requests are paused, fans will not be able to send you new requests.
                        </div>
                        <div className='dashboard-explination'>
                            Select "Save changes" for the change to take effect.
                        </div>
                        <div className="pause-toggle-container">
                            <div className={`pause-toggle ${athleteProfile.paused ? 'active' : ''}`} onClick={handlePauseToggle}>
                                <div className={`toggle-circle ${athleteProfile.paused ? 'active' : ''}`}></div>
                            </div>
                            <span className="pause-toggle-text">
                                {athleteProfile.paused ? 'New requests paused' : 'Accepting new requests'}
                            </span>
                        </div>
                    </>
                )}

                <div className="dashboard-faq-title">Public Information</div>
                <div className='dashboard-explination'>This information is displayed on your profile</div>

                {/* Photos section - same for all users */}
                <Form.Group className="mb-3">
                    <Form.Label>Photos</Form.Label>
                    <div className='dashboard-explination'>Contact the Infield team to change your profile or cover photos</div>
                </Form.Group>

                {/* Basic user fields */}
                <Form.Group className="mb-3">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                        type="text"
                        name="firstName"
                        value={athleteProfile.firstName}
                        onChange={handleProfileChange}
                    />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                        type="text"
                        name="lastName"
                        value={athleteProfile.lastName}
                        onChange={handleProfileChange}
                    />
                </Form.Group>

                {/* New fields for city and country */}
                <Form.Group className="mb-3">
                    <Form.Label>City</Form.Label>
                    <Form.Control
                        type="text"
                        name="city"
                        value={athleteProfile.city}
                        onChange={handleProfileChange}
                    />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Country</Form.Label>
                    <Select
                        className="country-select"
                        options={countries}
                        value={countries.find(country => country.label === athleteProfile.country)}
                        onChange={(value) => {
                            setAthleteProfile(prevProfile => ({
                                ...prevProfile,
                                country: value.label,
                                country_code: value.value
                            }));
                        }}
                    />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Sport</Form.Label>
                    <Form.Control
                        type="text"
                        name="sport"
                        value={athleteProfile.sport}
                        onChange={handleProfileChange}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Position</Form.Label>
                    <Form.Control
                        type="text"
                        name="position"
                        value={athleteProfile.position}
                        onChange={handleProfileChange}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Achievement highlights<div className='text-color-gray'></div></Form.Label>
                    {(athleteProfile.achievements || []).map((achievement, index) => (
                        <div key={index} className="dashboard-expertise-entry" style={{marginBottom: "10px"}}>
                            <Form.Control
                                type="text"
                                value={achievement}
                                onChange={(e) => handleAchievementChange(index, e.target.value)}
                                placeholder="E.g. a stat, championship, record, or affiliation"
                            />
                        </div>
                    ))}
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Bio</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows="5"
                        name="bio"
                        value={athleteProfile.bio}
                        onChange={handleProfileChange}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Areas of insight <div className='text-color-gray'>(eight maximum)</div></Form.Label>
                    {(athleteProfile.interests || []).map((interest, index) => (
                        <div key={index} className="dashboard-expertise-entry">
                            <Form.Control
                                type="text"
                                value={interest}
                                onChange={(e) => handleInterestChange(index, e.target.value)}
                                placeholder="e.g. Strength training, College Recruiting"
                            />
                            {athleteProfile.interests.length > 1 &&
                                <div className="dashboard-expertise-remove" onClick={() => removeInterest(index)}>
                                    × Remove
                                </div>
                            }
                        </div>
                    ))}
                    {athleteProfile.interests && athleteProfile.interests.length < 8 &&
                        <div className="dashboard-expertise-add" onClick={addInterest}>
                            + Add an insight
                        </div>
                    }
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Instagram <div className='text-color-gray'>(exclude the "@" symbol)</div></Form.Label>
                    <Form.Control
                        type="text"
                        name="instagram"
                        value={athleteProfile.instagram}
                        onChange={handleProfileChange}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>TikTok <div className='text-color-gray'>(exclude the "@" symbol)</div></Form.Label>
                    <Form.Control
                        type="text"
                        name="tiktok"
                        value={athleteProfile.tiktok}
                        onChange={handleProfileChange}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>X/Twitter <div className='text-color-gray'>(exclude the "@" symbol)</div></Form.Label>
                    <Form.Control
                        type="text"
                        name="x"
                        value={athleteProfile.x}
                        onChange={handleProfileChange}
                    />
                </Form.Group>
                <div className="dashboard-faq-title">Account Information</div>
                <Form.Group className="mb-3">
                    <Form.Label>No-go topics</Form.Label>
                    <div className='dashboard-explination'>Let our team know if there are any question topics that you don't wish to see.</div>
                    <Form.Control
                        as="textarea"
                        rows="3"
                        name="no_go_topics"
                        value={athleteProfile.no_go_topics}
                        onChange={handleProfileChange}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Reset Password</Form.Label>
                    <div className='roster-text'>
                        <a className='contact-redirect-link' href='/passwordreset'><u>Click here to reset your password.</u></a>
                    </div>
                </Form.Group>
                <button className="dashboard-profile-changes" onClick={handleProfileSubmit}>
                    Save changes
                </button>
            </Form>
            {showCropper && (
                <ImageCropper
                    image={cropperImage}
                    onCropComplete={handleCropComplete}
                    aspect={cropperType === 'profile' ? 1 : 16 / 9}
                />
            )}
        </div>
    );
}

export default EditProfileTab;
