import React from 'react';

const SecureVideoPlayer = ({ src, className }) => {
  const iframeUrl = `/secure-video-player.html?video=${encodeURIComponent(src)}`;

  return (
    <div className={`secure-video-container ${className}`}>
      <iframe
        src={iframeUrl}
        frameBorder="0"
        allowFullScreen
        sandbox="allow-scripts allow-same-origin allow-fullscreen"
        title="Secure Video Player"
      />
    </div>
  );
};

export default SecureVideoPlayer;