import AthleteForm from "../components/AthleteForm"

function AthleteJoinPage() {
    return(
        <div>
            <AthleteForm></AthleteForm>
        </div>
    );
}

export default AthleteJoinPage;