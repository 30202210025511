import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';

const ConfirmationModal = ({ isOpen, onConfirm, onCancel, title, message, confirmText, cancelText, showTextarea, onTextareaChange, placeholderText, initialTextareaValue }) => {
  const [textareaValue, setTextareaValue] = useState('');

  // Initialize textareaValue with initialTextareaValue when the modal opens
  useEffect(() => {
    if (isOpen) {
      setTextareaValue(initialTextareaValue || '');
    }
  }, [isOpen, initialTextareaValue]);

  // Handle textarea change
  const handleTextareaChange = (e) => {
    const value = e.target.value;
    setTextareaValue(value);
    if (onTextareaChange) {
      onTextareaChange(value);
    }
  };

  if (!isOpen) return null;

  return (
    <div className='confirmation-modal-overlay'>
      <div className='confirmation-modal'>
        <div className='confirmation-modal-title'>{title}</div>
        <div className='confirmation-modal-message'>{message}</div>
        {/* Conditionally display the textarea */}
        {showTextarea && (
          <div>
            <textarea
              className='confirmation-modal-textarea'
              rows="auto"
              placeholder={placeholderText}
              value={textareaValue}
              onChange={handleTextareaChange}
            />
          </div>
        )}
        <div className='confirmation-modal-button-container'>
          <Button onClick={onConfirm} className='confirmation-modal-confirm'>
            {confirmText || 'Yes'}
          </Button>
          <Button onClick={onCancel} className='confirmation-modal-cancel'>
            {cancelText || 'No'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
