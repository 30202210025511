import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { supabase } from '../SupabaseClient';

const AthleteComponent = ({athlete}) => {
    const {firstName, lastName, profile_pic, sport, position, achievements, username} = athlete;

    return (
        <Link className="athlete-entry" to={`/athletes/${username}`} key={username}>
            <div className="athlete-entry-image-container">
                <img className="athlete-entry-image" src={profile_pic} alt={`${firstName} ${lastName}`} />
                <div className='athlete-entry-image-overlay'></div>
                <div className='athlete-entry-info'>
                    <div className='athlete-name'>
                        {firstName} {lastName}
                    </div>

                    { position !== "" ?
                    <div className='athlete-sport'>
                        {position}, {sport}
                    </div>
                    :
                    <div className='athlete-sport'>
                        {sport}
                    </div>}

                    <div className='athlete-accolade'>
                        {achievements[0]}
                    </div>
                </div>
            </div>
        </Link>
    );
};

function AthleteListPage() {
    const [athletes, setAthletes] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchAthletes = async () => {
            const { data, error } = await supabase
                .from('users')
                .select('*')
                .eq('role', 'creator')
                .eq('paused', 'FALSE');
            if (error) {
                console.error('Error fetching athletes:', error);
            } else {
                setAthletes(data);
            }
        };
        fetchAthletes();
        setLoading(false);
    }, []);
    
    // Appearing animation for athlete cards
    useEffect(() => {
        const athleteEntries = document.querySelectorAll('.athlete-entry');
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry, index) => {
                if (entry.isIntersecting) {
                    entry.target.style.animationDelay = `${index * 0.3}s`; // Staggered delay
                    entry.target.classList.add('fade-in');
                    observer.unobserve(entry.target); // Stop observing once the animation is applied
                }
            });
        }, {
            threshold: 0.1 // Adjust this value as needed
        });
        athleteEntries.forEach(entry => {
            observer.observe(entry);
        });
        return () => {
            athleteEntries.forEach(entry => {
                observer.unobserve(entry);
            });
        };
    }, [athletes]);

    if (loading) {
        return (
            <div className="dashboard-page-format">
                <div className="loader-ring"><div></div><div></div><div></div><div></div></div>
            </div>
        )
    }
    
    return(
        <div className='home-format'>
            <div className='athlete-wrapper'>
                <div className='athlete-top-row'>
                    <div className='athlete-top-header'>Meet the Infielders</div>
                    <div className='athlete-top-intro'>
                        Select an athlete's profile to learn how they can help you.
                    </div>
                </div>
                <div sm={1} lg={2} className='athlete-row'>
                    {athletes.map((athlete) => (
                        <AthleteComponent key={athlete.username} athlete={athlete} />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default AthleteListPage;