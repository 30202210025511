import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useParams, Link } from "react-router-dom";
import { Button } from 'react-bootstrap';
import Select, { components } from 'react-select';
import countryList from 'react-select-country-list';
import { 
  FacebookShareButton, 
  TwitterShareButton, 
  WhatsappShareButton, 
  LinkedinShareButton, 
  RedditShareButton, 
  FacebookIcon, 
  TwitterIcon, 
  WhatsappIcon, 
  LinkedinIcon, 
  RedditIcon 
} from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaCopy } from 'react-icons/fa';
import { supabase } from '../SupabaseClient';
import axios from 'axios';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import { MdOutlinePeopleAlt } from "react-icons/md";
import { BsPersonVideo } from "react-icons/bs";
import { RiRefund2Line } from "react-icons/ri";
import { AiOutlineSafetyCertificate } from "react-icons/ai";
import ReCAPTCHA from "react-google-recaptcha";
import ConfirmationAlert from '../components/ConfirmationAlert';
import { loadStripe } from '@stripe/stripe-js';
import { PaymentElement, useStripe, useElements, Elements } from '@stripe/react-stripe-js';

// Initialize Stripe
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`);

const CustomOption = (props) => {
  return (
    <components.Option {...props}>
      {props.data.profile_pic && (
        <img
          src={props.data.profile_pic}
          alt={props.data.label}
          style={{ width: 30, height: 30, borderRadius: '50%', marginRight: 10, objectFit: "cover", overflowClipMargin: "unset"}}
        />
      )}
      {props.data.label}
    </components.Option>
  );
};

function RequestPage() {
  const { username } = useParams();
  const [selectedAthlete, setSelectedAthlete] = useState(null);
  const countries = useMemo(() => countryList().getData(), []);
  const [athletes, setAthletes] = useState([]);
  const defaultPrice = 0; // (In Cents !!) The price of the request without any donations
  const [discountCode, setDiscountCode] = useState(''); 
  const [isDiscountApplied, setIsDiscountApplied] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0); // Initialize totalPrice with $15.00
  const [selectedDonation, setSelectedDonation] = useState(0); // Initialize selectedDonation
  const [isCustomDonationActive, setIsCustomDonationActive] = useState(false);
  const [formData, setFormData] = useState({
    athlete: '',
    athleteID: '',
    city: '',
    countryName: '',
    countryValue: '',
    messageType: '',
    firstName: '',
    lastName: '',
    dob: '',
    email: '',
    subject: '',
    message: '',
    status: 'new',
    video: null,
    paid: 0
  });

  useEffect(() => {
    const fetchAthletes = async () => {
      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('role', 'creator')
        .eq('paused', 'FALSE')
      if (error) {
        console.error('Error fetching athletes:', error);
      } else {
        setAthletes(data);
        if (username) {
          const { data: dataUsername, error: errorUsername } = await supabase
            .from('users')
            .select('*')
            .eq('username', username);
          if (errorUsername) {
            console.error('Error fetching athlete with username:', errorUsername);
          } else if (dataUsername && !dataUsername[0].paused) { // Check if dataUsername is not null and access the first element
            const athleteToSelect = data.find((athlete) => athlete.username === username);
            setSelectedAthlete(athleteToSelect);
            setFormData({
              ...formData,
              athlete: athleteToSelect.username,
              athleteID: athleteToSelect.uuid,
            });
          }
        }
      }
    };
    fetchAthletes();
  }, [username]);


  const athleteOptions = athletes.map((athlete) => ({
    value: athlete.username,
    username: athlete.username,
    label: `${athlete.firstName} ${athlete.lastName}`,
    uuid: athlete.uuid,
    profile_pic: athlete.profile_pic
  }));


  const handleSelectChange = (selectedOption) => {
    setSelectedAthlete(selectedOption);
    setFormData({
      ...formData,
      athlete: selectedOption.value,
      athleteID: selectedOption.uuid,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };


  const [videoThumbnail, setVideoThumbnail] = useState(null);
  const fileInputRef = useRef(null);
  const handleFileChange = (e) => {
    setVideoThumbnail(null);
    setFormData({ ...formData, video: e.target.files[0] });
    const file = e.target.files[0];
    if (file) {
      const videoUrl = URL.createObjectURL(file);
      const videoElement = document.createElement('video');
      videoElement.src = videoUrl;
      videoElement.onloadedmetadata = () => {
        if (videoElement.duration > 21) {
          alert("The selected video is too long. Please select a video that is 20 seconds or less.");
          clearVideo();
          return;
        } else {
          setFormData({ ...formData, video: file });
          setVideoThumbnail(videoUrl);
        }
      };
    }
  };
  const clearVideo = () => {
    setVideoThumbnail(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showConfirmationAlert, setShowConfirmationAlert] = useState(false);

  const [message, setMessage] = useState('');
  const handleMessageChange = (event) => {
    setMessage(event.target.value);
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  const calculateRowCount = () => {
    const rowCount = (message.match(/\n/g) || []).length + 1;
    return rowCount < 3 ? 3 : rowCount;
  };


  const isFormValid = () => {
    const { athlete, subject, messageType, firstName, lastName, dob, city, countryName, email, video, message } = formData;
    const isVideoValid = messageType === 'video' ? video !== null : true;
    const isTextValid = messageType === 'text' ? message !== '' : true;
    const isContentValid = isVideoValid || isTextValid;
    return athlete && subject && messageType && firstName && lastName && dob && city && countryName && email && isContentValid;
  };

  const handleDiscountApply = () => {
    if (discountCode.trim() === 'TRYINFIELD') {
      setIsDiscountApplied(true);
      setTotalPrice(0 + selectedDonation);
    } else {
      alert('Invalid discount code.');
    }
  };

  const handleDonation = (amount) => {
    if (selectedDonation === amount) {
      setSelectedDonation(0);
      setTotalPrice(isDiscountApplied ? 0 : defaultPrice);
    } else {
      setSelectedDonation(amount);
      setTotalPrice((isDiscountApplied ? defaultPrice : defaultPrice) + amount);
    }
    setIsCustomDonationActive(false);
  };

  const toggleCustomDonation = () => {
    setIsCustomDonationActive(!isCustomDonationActive);
    if (isCustomDonationActive) {
      setSelectedDonation(0);
      setTotalPrice(isDiscountApplied ? 0 : defaultPrice);
    }
  };

  const handleCustomDonationChange = (event) => {
    const customAmount = parseFloat(event.target.value);
    if (customAmount >= 2) {
      setSelectedDonation(customAmount * 100);
      setTotalPrice((isDiscountApplied ? 0 : defaultPrice) + customAmount * 100);
    } else {
      setSelectedDonation(0);
      setTotalPrice(isDiscountApplied ? 0 : defaultPrice);
    }
  };

  const [showConfirmationForm, setShowConfirmationForm] = useState(false);
  const [clientSecret, setClientSecret] = useState('');

  const handleConfirm = async () => {
    setIsSubmitting(true);
    try {
      if (totalPrice > 0) {
        try {
          const response = await axios.post('https://infield-backend.vercel.app/create-payment-intent', {
            amount: totalPrice, 
            currency: 'usd', 
            capture_method: 'manual', 
            firstName: formData.firstName, 
            lastName: formData.lastName, 
            email: formData.email, 
            athlete_id: formData.athleteID,
            athlete_username: selectedAthlete.username,
            athlete_stripe_id: selectedAthlete.stripe_account_id,
            athlete_username: selectedAthlete.username,
            athlete_stripe_id: selectedAthlete.stripe_account_id
           });
          const { clientSecret, paymentIntentId } = response.data;
          setFormData(prevFormData => ({
            ...prevFormData,
            paymentIntentId,
            paid: totalPrice / 100
          }));
          setClientSecret(clientSecret);
          setShowConfirmationForm(true);
        } catch (error) {
          console.error('Error creating payment intent:', error);
        }
      } else {
        setFormData(prevFormData => ({
          ...prevFormData,
          paid: totalPrice / 100
        }));
        setShowConfirmationForm(true);
      }
    }
    finally {
      setIsSubmitting(false);
    }
  };

  const cancelSubmit = async () => {
    if (formData.paymentIntentId) {
      try {
        await axios.post('https://infield-backend.vercel.app/cancel-payment-intent', { paymentIntentId:formData.paymentIntentId });
        // await axios.post('http://localhost:3001/cancel-payment-intent', { paymentIntentId:formData.paymentIntentId });
        setFormData({ ...formData, paymentIntentId: '' });
      } catch (error) {
        console.error('Error cancelling payment intent:', error);
      }
    }
    setShowConfirmationForm(false);
  };

  return (
    <div className='contact-page-format'>
      {showConfirmationAlert && (
        <ConfirmationAlert
          message="Your request has been submitted!"
          onClose={() => setShowConfirmationAlert(false)}
        />
      )}
      {showConfirmationForm && totalPrice > 0 && clientSecret ? (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <ConfirmationForm
            formData={formData}
            cancelSubmit={cancelSubmit}
            totalPrice={totalPrice}
            isSubmitting={isSubmitting}
            setIsSubmitting={setIsSubmitting}
            selectedAthlete={selectedAthlete}
            clientSecret={clientSecret}
          />
        </Elements>
      ) : showConfirmationForm && totalPrice === 0 ? (
        <FreeConfirmationForm
          formData={formData}
          cancelSubmit={cancelSubmit}
          totalPrice={totalPrice}
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
          selectedAthlete={selectedAthlete}
        />
      ) : (
        <RequestForm
          formData={formData}
          setFormData={setFormData}
          selectedAthlete={selectedAthlete}
          athleteOptions={athleteOptions}
          handleSelectChange={handleSelectChange}
          handleChange={handleChange}
          handleFileChange={handleFileChange}
          videoThumbnail={videoThumbnail}
          clearVideo={clearVideo}
          handleMessageChange={handleMessageChange}
          calculateRowCount={calculateRowCount}
          isFormValid={isFormValid}
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
          countries={countries}
          totalPrice={totalPrice}
          handleDiscountApply={handleDiscountApply}
          selectedDonation={selectedDonation}
          handleDonation={handleDonation}
          isCustomDonationActive={isCustomDonationActive}
          toggleCustomDonation={toggleCustomDonation}
          handleCustomDonationChange={handleCustomDonationChange}
          handleConfirm={handleConfirm} // Pass handleConfirm to RequestForm
        />
      )}
    </div>
  );
}


function RequestForm({
  formData,
  setFormData,
  selectedAthlete,
  athleteOptions,
  handleSelectChange,
  handleChange,
  handleFileChange,
  videoThumbnail,
  clearVideo,
  handleMessageChange,
  calculateRowCount,
  isFormValid,
  isSubmitting,
  setIsSubmitting,
  countries,
  totalPrice,
  handleDiscountApply,
  selectedDonation,
  handleDonation,
  isCustomDonationActive,
  toggleCustomDonation,
  handleCustomDonationChange,
  handleConfirm
}) {
  const [isCollapsed, setIsCollapsed] = useState(true); // Define isCollapsed state
  const fileInputRef = useRef(null); // Define fileInputRef
  const [discountCode, setDiscountCode] = useState('');
  const [isDiscountApplied, setIsDiscountApplied] = useState(false);
  const [isCaptchaCompleted, setIsCaptchaCompleted] = useState(false);

  useEffect(() => {
    const athleteEntries = document.querySelectorAll('.request-instructions-container');
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry, index) => {
        if (entry.isIntersecting) {
          entry.target.style.animationDelay = `${index * 0.3}s`;
          entry.target.classList.add('fade-in');
          observer.unobserve(entry.target);
        }
      });
    }, {
      threshold: 0.1
    });
    athleteEntries.forEach(entry => {
      observer.observe(entry);
    });
    return () => {
      athleteEntries.forEach(entry => {
        observer.unobserve(entry);
      });
    };
  }, []);

  const calculateMaxDate = () => {
    const today = new Date();
    const maxDate = new Date(
      today.getFullYear() - 15,
      today.getMonth(),
      today.getDate()
    );
    return maxDate.toISOString().split('T')[0];
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  // Generate age options
  const ageOptions = Array.from({ length: 96 }, (_, i) => ({
    value: i + 15,
    label: `${i + 15}`
  }));

  return (
    <div className='contact-page-format'>
        <form className="request-form" onSubmit={(e) => { e.preventDefault(); handleConfirm(); }}>
          <div className="request-header">Make a Request!</div>
          <div className="request-title" onClick={toggleCollapse} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
            How Infield works
            {isCollapsed ? <AiOutlineDown style={{ marginLeft: '8px' }} /> : <AiOutlineUp style={{ marginLeft: '8px' }} />}
          </div>
          <div className={`request-collapsible-content ${isCollapsed ? 'request-collapsed' : 'request-expanded'}`}>
            <div className="request-instructions-container">
              <div><MdOutlinePeopleAlt className="request-instructions-icon" /></div>
              <div className="request-instructions">
                <b className="text-color-primary">Get face-to-face</b> with an Infielder by asking them a question in the form of video or text. Ask them about their training, lifestyle, or even just say hi! <b className="text-color-primary">No signup required.</b>
              </div>
            </div>
            <div className="request-instructions-container">
              <div><BsPersonVideo className="request-instructions-icon" /></div>
              <div className="request-instructions">
                <b className="text-color-primary">Receive a personalized video response</b> from the Infielder within 4 days if your request is approved.
              </div>
            </div>
            <div className="request-instructions-container">
              <div><RiRefund2Line className="request-instructions-icon" /></div>
              <div className="request-instructions">
                <b className="text-color-primary">No charges are made</b> until you receive your video response.
              </div>
            </div>
            <div className="request-instructions-container">
              <div><AiOutlineSafetyCertificate className="request-instructions-icon" /></div>
              <div className="request-instructions">
                <b className="text-color-primary">Keep it sincere.</b> We auto-reject requests that are inappropriate, hateful, or spam.
              </div>
            </div>
          </div>
          <div className="request-title">1 - Choose an Infielder</div>
          <div className="request-athlete-container">
            <Select
              className="request-athlete-dropdown"
              value={athleteOptions.find(option => option.value === formData.athlete)}
              onChange={handleSelectChange}
              options={athleteOptions}
              placeholder="Select an athlete"
              components={{ Option: CustomOption }}
            />
            {selectedAthlete && (
              <Link to={`/athletes/${selectedAthlete.username}`} style={{ display: "flex", alignItems: "center", flexWrap: "wrap", maxWidth: "100%" }}>
                <img
                  className="request-profile-picture"
                  src={selectedAthlete.profile_pic}
                  alt={`${selectedAthlete.value}`}
                />
                {/* <b style={{ marginLeft: "10px", wordBreak: "break-word", maxWidth: "calc(100% - 60px)" }}>{selectedAthlete.username}</b> */}
              </Link>
            )}
          </div>
          <div className="request-title">2 - Create your request</div>
          <div className='contact-info-header'>Request Subject<span className="required-asterisk">*</span></div>
          <input className='contact-info' name="subject" value={formData.subject} type="text" maxLength="50" required placeholder=" Description of your request" onChange={handleChange} />
          <div className='contact-info-header'>Send a written or video request<span className="required-asterisk">*</span></div>
          <div style={{ color: "gray", marginBottom: "5px" }}>Athletes will always respond to your request with a video</div>
          <div className="request-type-parent">
            <label>
              <input
                type="radio"
                name="messageType"
                value="video"
                checked={formData.messageType === 'video'}
                onChange={() => setFormData({ ...formData, messageType: 'video', message: '' })}
                style={{ marginRight: '8px' }}
              />
              <div className="request-type-video"><b>Upload or record a video</b><div className="text-color-gray"> (20 seconds max)</div></div>
            </label>
            <label>
              <input
                type="radio"
                name="messageType"
                value="text"
                checked={formData.messageType === 'text'}
                onChange={() => {
                  setFormData({ ...formData, messageType: 'text', message: '' });
                  clearVideo();
                }}
                style={{ marginRight: '8px' }}
              />
              <div className="request-type-text"><b>Write a message</b></div>
            </label>
          </div>
          {formData.messageType === 'text' &&
            <textarea
              className='contact-message'
              value={formData.message}
              required
              spellCheck="true"
              maxLength="500"
              name="message"
              placeholder="500 characters max"
              ref={fileInputRef}
              onChange={handleMessageChange}
              rows={calculateRowCount()}
            />
          }
          {formData.messageType === 'video' && <div className="request-video-upload">
            <div className="request-subtitle">Upload a video from your device</div>
            <input
              type="file"
              name="video"
              required
              accept="video/*"
              className="request-video-button"
              id="file"
              onChange={handleFileChange} />
            <label htmlFor="file">{videoThumbnail ? "Change video" : "Upload video"}</label>
            {videoThumbnail && (
              <video
                width="200"
                controls
                className="request-video-thumbnail"
              >
                <source src={videoThumbnail} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
          </div>}
          <div className="request-title">3 - Enter your contact info</div>
          <div className='contact-info-header'>First name<span className="required-asterisk">*</span></div>
          <input className='contact-info' name="firstName" value={formData.firstName} key="firstName" type="text" maxLength="35" required onChange={handleChange} />
          <div className='contact-info-header'>Last name<span className="required-asterisk">*</span></div>
          <input className='contact-info' name="lastName" value={formData.lastName} type="text" maxLength="35" required onChange={handleChange} />
          <div className='contact-info-header'>Date of birth<span className="required-asterisk">*</span></div>
          <div style={{ color: "gray", marginBottom: "5px" }}>You must be at least 15 years old to use Infield</div>
          <input 
            className='contact-info' 
            name="dob" 
            value={formData.dob}
            type="date"
            min="1900-01-01" // Reasonable minimum date
            max={calculateMaxDate()} // Maximum date (14 years ago)
            required 
            onChange={handleChange}
          />
          <div className='contact-info-header'>City<span className="required-asterisk">*</span></div>
          <input className='contact-info' name="city" value={formData.city} type="text" maxLength="35" required onChange={handleChange} />
          <div className='contact-info-header'>Country<span className="required-asterisk">*</span></div>
          <Select
            className="request-country-dropdown"
            name="country"
            required
            options={countries}
            value={formData.country}
            onChange={(value) => {
              setFormData({
                ...formData,
                countryName: value.label,
                countryValue: value.value,
              });
            }}
          />
          <div className='contact-info-header'>Email address<span className="required-asterisk">*</span></div>
          <div style={{ color: "gray", marginBottom: "5px" }}>We will send the athlete's response to this email address</div>
          <input className='contact-info' name="email" value={formData.email} type="email" required maxLength="35" onChange={handleChange} />
          <div className="request-title">4 - Optional payment</div>
          {/* <div className="discount-container">
            <input
              type="text"
              className="discount-input"
              placeholder="Enter discount code"
              value={discountCode}
              onChange={(e) => setDiscountCode(e.target.value)}
            />
            <button type="button" className="discount-button" onClick={handleDiscountApply}>
              Apply
            </button>
          </div> */}
          {isDiscountApplied && <div className="discount-message">Discount applied!</div>}

          <div className="donation-container">
            <div>Consider sending the athlete something for their time</div>
            <div className="donation-subcontainer">
              <button
                type="button"
                className={`donation-button ${selectedDonation === 500 ? 'selected' : ''}`}
                onClick={() => handleDonation(500)}
              >
                $5
              </button>
              <button
                type="button"
                className={`donation-button ${selectedDonation === 1000 ? 'selected' : ''}`}
                onClick={() => handleDonation(1000)}
              >
                $10
              </button>
              <button
                type="button"
                className={`donation-button ${isCustomDonationActive ? 'selected' : ''}`}
                onClick={toggleCustomDonation}
              >
                Custom
              </button>
              {isCustomDonationActive && (
                <input
                  type="number"
                  min="1"
                  placeholder="Enter amount"
                  className="donation-input"
                  onChange={handleCustomDonationChange}
                />
              )}
            </div>
          </div>
          <div className="price-display">
            <span>Order total</span>
            {/* <span>{totalPrice === 0 ? 'FREE' : `$${(totalPrice / 100).toFixed(2)}`}</span> */}
            <span>{`$${(totalPrice / 100).toFixed(2)}`}</span>
          </div>
          <ReCAPTCHA
            sitekey="6LeBkigqAAAAAEDBIpSKa5W1cZDkNBdI3GNc9Ztf"
            onChange={() => setIsCaptchaCompleted(true)}
            onExpired={() => setIsCaptchaCompleted(false)}
          />
          <button
            className="request-button"
            type="button"
            disabled={!isFormValid() || isSubmitting || !isCaptchaCompleted}
            onClick={handleConfirm}
          >
            {isSubmitting ? (
              <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                Confirming details
                <div className="loader-button" style={{ marginLeft: '10px' }}>
                </div>
              </div>
            ) : (
              "Confirm details"
            )}
          </button>
        </form>
    </div>
  );
}

function ConfirmationForm({ formData, cancelSubmit, totalPrice, isSubmitting, setIsSubmitting, selectedAthlete, clientSecret }) {
  const stripe = useStripe();
  const elements = useElements();
  const [submitted, setSubmitted] = useState(false);
  const [isPaymentValid, setIsPaymentValid] = useState(false);

  useEffect(() => {
    if (!elements) return;

    const paymentElement = elements.getElement(PaymentElement);

    if (paymentElement) {
      const handleChange = (event) => {
        setIsPaymentValid(event.complete);
      };

      paymentElement.on('change', handleChange);

      return () => {
        paymentElement.off('change', handleChange);
      };
    }
  }, [elements]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    try {
      // Step 1: Handle payment if needed
      if (totalPrice > 0) {
        if (!stripe || !elements) {
          throw new Error('Stripe.js has not loaded yet. Please try again.');
        }

        const { error: submitError } = await elements.submit();
        if (submitError) {
          throw new Error('Failed to submit payment form: ' + submitError.message);
        }

        const { error, paymentIntent } = await stripe.confirmPayment({
          elements,
          clientSecret,
          confirmParams: {},
          redirect: 'if_required',
        });

        if (error) {
          throw new Error('Payment failed: ' + error.message);
        }

        if (paymentIntent.status !== 'succeeded' && paymentIntent.status !== 'requires_capture') {
          throw new Error('Payment was not successful. Please try again.');
        }
      }

      // Step 2: Check/create user and conversation
      const { data: userResponse, error: userError } = await axios.post('https://infield-backend.vercel.app/check-existing-user', {
        responderUuid: selectedAthlete.uuid,
        firstName: formData.firstName,
        lastName: formData.lastName,
        age: formData.age,
        city: formData.city,
        country: formData.countryName,
        email: formData.email
      });

      if (userError) throw new Error('Failed to process user data');

      // Step 3: Upload video if needed
      let videoPath = null;
      if (formData.messageType === 'video' && formData.video) {
        try {
          const fileName = `${Date.now()}_${formData.video.name}`;
          const filePath = `${userResponse.conversationId}/${fileName}`;
          
          const { data, error } = await supabase.storage
            .from('conversations')
            .upload(filePath, formData.video, {
              contentType: formData.video.type
            });

          if (error) throw error;

          // Store the relative path instead of the public URL
          videoPath = filePath;
        } catch (error) {
          // Clean up video if upload fails
          if (videoPath) {
            await supabase.storage
              .from('conversations')
              .remove([videoPath]);
          }
          throw new Error('Video upload failed');
        }
      }

      // Step 4: Send request
      await axios.post('https://infield-backend.vercel.app/send-request', {
        conversationId: userResponse.conversationId,
        senderId: userResponse.requesterUuid,
        receiverId: selectedAthlete.uuid,
        subject: formData.subject,
        messageType: formData.messageType,
        videoPath, // Changed from videoUrl to videoPath
        message: formData.message,
        paymentIntentId: formData.paymentIntentId,
        paymentAmount: formData.paid,
        emailData: {
          reply_to: 'info@infieldconnect.co',
          requester_email: formData.email,
          requester_name: formData.firstName,
          athlete_name: selectedAthlete.label,
        }
      });

      setSubmitted(true);

    } catch (error) {
      console.error('Form submission failed:', error);
      alert(`Submission failed: ${error.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const [copied, setCopied] = useState(false);
  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <>
      {!submitted && (
        <form className="request-form" onSubmit={handleSubmit}>
          <div className="request-header">Confirm Your Request</div>
          <div className="request-title">Request details</div>
          <div className="summary">
            <div><b>Name:</b> {formData.firstName} {formData.lastName}</div>
            <div><b>From:</b> {formData.city}, {formData.countryName}</div>
            <div><b>Email:</b> {formData.email}</div>
            <div><b>Request subject:</b> {formData.subject}</div>
            <div><b>Request athlete:</b> {selectedAthlete.label} (@{formData.athlete})</div>
          </div>
          {totalPrice > 0 && (
            <>
              <div className="request-title">Payment details</div>
              <PaymentElement />
            </>
          )}
          <div className="price-display">
            <span>Order total</span>
            <span>{`$${(totalPrice / 100).toFixed(2)}`}</span>
          </div>
          <button
            className="request-button"
            type="submit"
            disabled={totalPrice > 0 && (!stripe || !isPaymentValid || isSubmitting)}
          >
            {isSubmitting ? (
              <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                Submitting
                <div className="loader-button" style={{ marginLeft: '10px' }}>
                </div>
              </div>
            ) : (
              "Submit request"
            )}
          </button>
          <button className="cancel-button" type="button" onClick={cancelSubmit} disabled={isSubmitting}>Cancel request</button>
          <p className="legal-agreement">
            By submitting a request, you consent for us to collect and use your personal data soley for facilitating your experience on the platform. You can learn more by viewing our <a target="_blank"  href="https://www.infieldconnect.co/termsofservice">Terms of Service</a> and <a target="_blank" href="https://www.infieldconnect.co/privacypolicy">Privacy Policy</a>.
          </p>
        </form>
      )}
      {submitted && (
        <div className="contact-form">
          <div className='request-title'>
            Thank you for your request!
          </div>
          <div className='contact-received-text'>
            If you don't see a confirmation email in your inbox, please check your spam folder. If your request is approved, the athlete will respond within 3 days, or you will receive a full refund.
          </div>
          <div className='contact-received-text' style={{ marginTop: "10px" }}>
            <b>Know someone who might want to ask an Infielder? Share our page!</b>
          </div>
          <div className='share-buttons' style={{ display: 'flex', gap: '10px' }}>
            <FacebookShareButton url="https://www.infieldconnect.co/" quote="Check out Infield! It lets fans get face-to-face with their favorite athletes.">
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <TwitterShareButton url="https://www.infieldconnect.co/" title="Check out Infield! It lets fans get face-to-face with their favorite athletes.">
              <TwitterIcon size={32} round />
            </TwitterShareButton>
            <WhatsappShareButton url="https://www.infieldconnect.co/" title="Check out Infield! It lets fans get face-to-face with their favorite athletes.">
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
            <LinkedinShareButton url="https://www.infieldconnect.co/" title="Check out Infield!" summary="Infield lets fans get face-to-face with their favorite athletes.">
              <LinkedinIcon size={32} round />
            </LinkedinShareButton>
            <RedditShareButton url="https://www.infieldconnect.co/" title="Check out Infield! It lets fans get face-to-face with their favorite athletes.">
              <RedditIcon size={32} round />
            </RedditShareButton>
            <CopyToClipboard text="https://www.infieldconnect.co/" onCopy={handleCopy}>
              <button style={{ background: 'none', color: "gray", border: 'none', cursor: 'pointer' }}>
                <FaCopy size={32} />
              </button>
            </CopyToClipboard>
            {copied && <span style={{ color: 'green' }}>Copied!</span>}
          </div>
        </div>
      )}
    </>
  );
}

function FreeConfirmationForm({ formData, cancelSubmit, totalPrice, isSubmitting, setIsSubmitting, selectedAthlete }) {
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      // Step 1: Check/create user and conversation
      const { data: userResponse } = await axios.post('https://infield-backend.vercel.app/check-existing-user', {
        responderUuid: selectedAthlete.uuid,
        firstName: formData.firstName,
        lastName: formData.lastName,
        dob: formData.dob,
        city: formData.city,
        country: formData.countryName,
        email: formData.email
      });

      // Step 2: Upload video if needed
      let videoPath = null;
      if (formData.messageType === 'video' && formData.video) {
        try {
          const fileName = `${Date.now()}_${formData.video.name}`;
          const filePath = `${userResponse.conversationId}/${fileName}`;
          
          const { data, error } = await supabase.storage
            .from('conversations')
            .upload(filePath, formData.video, {
              contentType: formData.video.type
            });

          if (error) throw error;

          // Store the relative path instead of the public URL
          videoPath = filePath;
        } catch (error) {
          // Clean up video if upload fails
          if (videoPath) {
            await supabase.storage
              .from('conversations')
              .remove([videoPath]);
          }
          throw new Error('Video upload failed');
        }
      }

      // Step 3: Send request
      await axios.post('https://infield-backend.vercel.app/send-request', {
        conversationId: userResponse.conversationId,
        senderId: userResponse.requesterUuid,
        receiverId: selectedAthlete.uuid,
        subject: formData.subject,
        messageType: formData.messageType,
        videoPath,
        message: formData.message,
        paymentIntentId: formData.paymentIntentId,
        paymentAmount: formData.paid,
        emailData: {
          reply_to: 'info@infieldconnect.co',
          requester_email: formData.email,
          requester_name: formData.firstName,
          athlete_name: selectedAthlete.label,
        }
      });
      
      setSubmitted(true);
    } catch (error) {
      console.error('Form submission failed:', error);
      if (error.response) {
        console.error('Error data:', error.response.data);
        console.error('Error status:', error.response.status);
        console.error('Error headers:', error.response.headers);
      } else if (error.request) {
        console.error('No response received:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
      alert(`Submission failed: ${error.response ? error.response.data.error : error.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const [copied, setCopied] = useState(false);
  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <>
      {!submitted && (
        <div className="request-form">
          <div className="request-header">Confirm Your Request</div>
          <div className="request-title">Request details</div>
          <div className="summary">
            <div><b>Name:</b> {formData.firstName} {formData.lastName}</div>
            <div><b>From:</b> {formData.city}, {formData.countryName}</div>
            <div><b>Email:</b> {formData.email}</div>
            <div><b>Request subject:</b> {formData.subject}</div>
            <div><b>Request athlete:</b> {selectedAthlete.label} (@{formData.athlete})</div>
          </div>
          <div className="price-display" style={{ marginTop: "20px" }}>
            <span>Order total</span>
            <span>{`$${(totalPrice / 100).toFixed(2)}`}</span>
          </div>
          <button
            className="request-button"
            type="submit"
            disabled={isSubmitting}
            onClick={handleSubmit}
          >
            {isSubmitting ? (
              <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                Submitting
                <div className="loader-button" style={{ marginLeft: '10px' }}>
                </div>
              </div>
            ) : (
              "Submit request"
            )}
          </button>
          <button className="cancel-button" onClick={cancelSubmit} disabled={isSubmitting}>Cancel request</button>
          <p className="legal-agreement">
            By booking, you agree to Infield's <Link to="/termsofservice">Terms of Service</Link> and <Link to="/privacypolicy">Privacy Policy</Link>.
          </p>
        </div>
      )}
      {submitted && (
        <div className="contact-form">
          <div className='request-title'>
            Thank you for your request!
          </div>
          <div className='contact-received-text'>
            Once your request is approved, the athlete will respond within 3 days, or you won't be charged. If you don't see a confirmation email or a notification of the athlete's response, please check your spam folder.
          </div>
          <div className='contact-received-text' style={{ marginTop: "10px" }}>
            <b>Know someone who might want to ask an Infielder? Share our page!</b>
          </div>
          <div className='share-buttons' style={{ display: 'flex', gap: '10px' }}>
            <FacebookShareButton url="https://www.infieldconnect.co/" quote="Check out Infield! It lets fans get face-to-face with their favorite athletes.">
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <TwitterShareButton url="https://www.infieldconnect.co/" title="Check out Infield! It lets fans get face-to-face with their favorite athletes.">
              <TwitterIcon size={32} round />
            </TwitterShareButton>
            <WhatsappShareButton url="https://www.infieldconnect.co/" title="Check out Infield! It lets fans get face-to-face with their favorite athletes.">
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
            <LinkedinShareButton url="https://www.infieldconnect.co/" title="Check out Infield!" summary="Infield lets fans get face-to-face with their favorite athletes.">
              <LinkedinIcon size={32} round />
            </LinkedinShareButton>
            <RedditShareButton url="https://www.infieldconnect.co/" title="Check out Infield! It lets fans get face-to-face with their favorite athletes.">
              <RedditIcon size={32} round />
            </RedditShareButton>
            <CopyToClipboard text="https://www.infieldconnect.co/" onCopy={handleCopy}>
              <button style={{ background: 'none', color: "gray", border: 'none', cursor: 'pointer' }}>
                <FaCopy size={32} />
              </button>
            </CopyToClipboard>
            {copied && <span style={{ color: 'green' }}>Copied!</span>}
          </div>
          <div className='request-title' style={{ marginBottom: "5px" }}>
            Want to view all of your requests in one place?
          </div>
          <Link to="/createaccount" className="request-button" style={{ textDecoration: 'none', display: 'inline-block', textAlign: 'center' }}>
            Create a free account
          </Link>
        </div>
      )}
    </>
  );
}

export default RequestPage;
