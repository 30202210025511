import React, { useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import ReCAPTCHA from "react-google-recaptcha";
import { supabase } from '../SupabaseClient';

function AthleteForm() {
    // Functions that change the contact page upon submission
    const form = useRef();
    const [submitted, setSubmitted] = useState(false);

    // Functions to adjust message box height dynamically
    const [message, setMessage] = useState('');
    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };
    const calculateRowCount = () => {
        const rowCount = (message.match(/\n/g) || []).length + 1;
        return rowCount < 3 ? 3 : rowCount;
    };

    // Functions to adjust message box height dynamically
    const [messageWhy, setMessageWhy] = useState('');
    const handleMessageChangeWhy = (event) => {
        setMessageWhy(event.target.value);
    };
    const calculateRowCountWhy = () => {
        const rowCountWhy = (messageWhy.match(/\n/g) || []).length + 1;
        return rowCountWhy < 3 ? 3 : rowCountWhy;
    };

    // State to store the CAPTCHA token
    const [captchaToken, setCaptchaToken] = useState(null);

    // Function to handle CAPTCHA change
    const handleCaptchaChange = (token) => {
        setCaptchaToken(token);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if CAPTCHA is completed
        if (!captchaToken) {
            alert("Please complete the CAPTCHA");
            return;
        }

        const formElements = document.querySelector("form");
        const formData = new FormData(formElements);
        const firstName = formData.get("First Name");
        const lastName = formData.get("Last Name");
        const email = formData.get("Email");
        const city = formData.get("City");
        const country = formData.get("Country");
        const social_platform = formData.get("Social Media Platform");
        const social_account = formData.get("Social Media Account");
        const sport = formData.get("Sport");
        const content = formData.get("Why Infield");

        // Insert data into Supabase table
        const { error } = await supabase
            .from('athlete_form')
            .insert([
                { first_name: firstName, last_name: lastName, email: email, city: city, country: country, social_platform: social_platform, social_account: social_account, sport: sport, content: content }
            ]);

        if (error) {
            console.error('Error!', error.message);
        } else {
            setSubmitted(true);
        }
    };

    return (
        <div className='contact-page-format'>
            {!submitted && (
                <form className='contact-form' ref={form} onSubmit={handleSubmit}>
                    <div className='contact-title'>Become an Infielder</div>
                    <hr className='contact-line'></hr>
                    <div className='contact-description'>Are you an elite athlete or sports expert looking to monetize your expertise? Fill out the form
                        below, and someone from our team will get in touch.
                    </div>
                    <div className='contact-redirect'>If you are not an athlete, expert, or agent, but are looking to reach out for a business inquiry, 
                        click <a className='contact-redirect-link' href='/contact'><b>here</b></a>.
                    </div>
                    <div className='contact-info-header'>First name</div>
                    <input className='contact-info' name="First Name" type="text" required/>
                    <div className='contact-info-header'>Last name</div>
                    <input className='contact-info' name="Last Name" type="text" required/>
                    <div className='contact-info-header'>Email address</div>
                    <input className='contact-info' name="Email" type="email" required/>

                    <div className='contact-info-header'>City</div>
                    <input className='contact-info' name="City" type="text" required/>

                    <div className='contact-info-header'>Country</div>
                    <input className='contact-info' name="Country" type="text" required/>

                    <div className='contact-info-header'>Primary social media platform</div>
                    <select className='contact-info' name="Social Media Platform">
                        <option value="" disabled selected>Select one</option>
                        <option value="facebook">Facebook</option>
                        <option value="instagram">Instagram</option>
                        <option value="tiktok">Tiktok</option>
                        <option value="x">X</option>
                    </select>

                    <div className='contact-info-header'>Social media account username</div>
                    <input className='contact-info' name="Social Media Name" type="text" required/>

                    <div className='contact-info-header'>Primary Sport or Focus</div>
                    <input className='contact-info' name="Sport" type="text" required/>

                    <div className='contact-info-header'>Why are you interested in Infield?</div>
                    <textarea 
                        className='contact-message'
                        value={messageWhy}
                        required
                        spellCheck="true"
                        name="Why Infield"
                        onChange={handleMessageChangeWhy}
                        rows={calculateRowCountWhy()}
                    />
                    <ReCAPTCHA 
                        sitekey="6LeBkigqAAAAAEDBIpSKa5W1cZDkNBdI3GNc9Ztf" 
                        onChange={handleCaptchaChange} 
                    />
                    <input className='contact-button' type="submit" value="Send" />
                </form>
            )}
            {submitted && (
            <div className='contact-received'>
                <div className='contact-form'>
                    <div className='contact-received-title'>
                        Thank you for your interest!
                    </div>
                    <div className='contact-received-text'>
                        If we believe you may be a good fit for our community, someone from our team will reach out to you soon.
                    </div>
                    <Button className='contact-back-button' href="/">Exit</Button>
                </div>
            </div>
            )}
        </div>
    );
};

export default AthleteForm;