import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { supabase } from '../SupabaseClient';
import { TbMessages } from "react-icons/tb";
import { FaRegUser } from "react-icons/fa6";
import EditProfileTab from './EditProfileTab';
import ConversationsTab from './ConversationsTab';

function BasicDashboard({ session }) {
    const [userID, setUserID] = useState('');
    const [activeTab, setActiveTab] = useState('conversations');
    const [loading, setLoading] = useState(false);
    const [profilePicUrl, setProfilePicUrl] = useState('');
    const [athleteProfile, setAthleteProfile] = useState({
        firstName: '',
        lastName: '',
        instagram: '',
        tiktok: '',
        x: '',
        role: '',
        email: '',
        country: '',
        city: '',
        profile_pic: null,
    });

    useEffect(() => {
        setLoading(true);
        fetchAthleteProfile();
    }, [session]);

    const fetchDefaultProfilePic = async () => {
        const { data, error } = await supabase
            .storage
            .from('userMedia')
            .getPublicUrl('default/profilePicture/default_profilepic.png');

        if (error) {
            console.error('Error fetching default profile picture:', error.message);
            return null;
        }
        return data.publicUrl;
    };

    const fetchAthleteProfile = async () => {
        const { data: user, error: authError } = await supabase.auth.getUser();
        if (authError || !user) {
            console.error('Error fetching user:', authError);
            return;
        }
        const userID = user.user.id;
        const { data, error } = await supabase
            .from('users')
            .select('*')
            .eq('uuid', userID)
            .single();
        if (error) {
            console.error('Error fetching profile:', error);
        } else {
            setAthleteProfile(data);
            setUserID(userID);
        }
        setLoading(false);
    };

    useEffect(() => {
        const updateProfilePicUrl = async () => {
            if (athleteProfile.profile_pic) {
                setProfilePicUrl(athleteProfile.profile_pic);
            } else {
                const defaultPicUrl = await fetchDefaultProfilePic();
                setProfilePicUrl(defaultPicUrl);
            }
        };
        updateProfilePicUrl();
    }, [athleteProfile.profile_pic]);

    if (loading) {
        return (
            <div className="dashboard-page-format">
                <div className="loader-ring"><div></div><div></div><div></div><div></div></div>
            </div>
        );
    }

    return (
        <div className="dashboard-page-format">
            <div className="dashboard-form">
                <div className='dashboard-header-container'>
                    <div className='dashboard-header-profile'>
                        <img 
                            className="dashboard-profile-picture" 
                            src={profilePicUrl} 
                            alt="Profile"
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = '/default-avatar.png';
                            }}
                        />
                        <div className='dashboard-name'>{athleteProfile.firstName} {athleteProfile.lastName}</div>
                    </div>
                </div>
                <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)} className='dashboard-tab-title'>
                    <Tab eventKey="conversations"
                        title={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <TbMessages style={{marginRight: '8px' }}/>
                                <span className="tab-text">Conversations</span>
                            </div>
                        }
                    >
                        <ConversationsTab session={session} />
                    </Tab>
                    <Tab eventKey="profile"
                        title={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <FaRegUser style={{marginRight: '8px' }}/>
                                <span className="tab-text">Profile</span>
                            </div>
                        }
                    >
                        <EditProfileTab
                            session={session}
                            athleteProfile={athleteProfile}
                            setAthleteProfile={setAthleteProfile}
                            userID={userID}
                        />
                    </Tab>
                </Tabs>
            </div>
        </div>
    );
}

export default BasicDashboard; 