import React from 'react';

function DemoPage() {
    const embedUrl = `https://kqlfidbnqabdhhibdwov.supabase.co/storage/v1/object/public/publicMedia/Infield%20Demo%20Video.mp4?t=2024-11-12T02%3A54%3A02.973Z`;

    return (
        <div className="dashboard-page-format">
            <div className="demo-form">
                <div style={{ position: 'relative', width: '100%', paddingTop: '56.25%' }}>
                    <iframe
                        src={embedUrl}
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            border: 'none'
                        }}
                        allowFullScreen
                        title="Product Demo Video"
                    />
                </div>
            </div>
        </div>
    );
}

export default DemoPage; 