import React from 'react';

function PrivacyPolicy() {
  return (
    <div className="legal-container">
      <h1>Privacy Policy</h1>
      <p className="last-updated">Effective Date: October 24, 2024</p>
      
      <h2>Table of Contents</h2>
      <ol>
        <li><a href="#section1">Data We Collect</a></li>
        <li><a href="#section2">How We Use Your Data</a></li>
        <li><a href="#section3">Legal Basis for Processing Personal Data (GDPR Compliance)</a></li>
        <li><a href="#section4">Your Rights Under GDPR and Other Privacy Laws</a></li>
        <li><a href="#section5">California Residents (CCPA)</a></li>
        <li><a href="#section6">International Transfers</a></li>
        <li><a href="#section7">Data Security</a></li>
        <li><a href="#section8">Third-Party Services</a></li>
        <li><a href="#section9">Data Retention</a></li>
        <li><a href="#section10">Changes to This Policy</a></li>
        <li><a href="#section11">Contact Us</a></li>
      </ol>

      <h2 id="section1">Data We Collect</h2>
      <p>We aim to collect as little personal data as possible. The following information is collected to facilitate account creation and provide services:</p>
      <ul>
        <li><strong>Account Information</strong>: When you create an account, we collect your name, email address, and password. This data is solely used for account management and login.</li>
        <li><strong>Payment Information</strong>: We use third-party payment processors (e.g., Stripe) to handle all payments. We do not store any payment data on our servers. All payment information is processed securely by our payment partners in compliance with PCI-DSS standards.</li>
        <li><strong>Video Content</strong>: Video exchanges between experts and fans are conducted on our platform. We do not access or use the content of these videos for any other purpose.</li>
        <li><strong>Communications</strong>: Any communications between you and us (such as support requests) may be stored for quality control and future reference.</li>
      </ul>

      <h2 id="section2">How We Use Your Data</h2>
      <p>We do not use your personal data for any purpose beyond the scope of providing and improving our platform. Specifically, we:</p>
      <ul>
        <li>Use your email to communicate regarding your account or to respond to your queries.</li>
        <li>Ensure smooth functioning of video exchanges between experts and fans.</li>
        <li>Use anonymized or aggregated data to improve platform performance and security.</li>
        <li>Handle payments through third-party processors (e.g., Stripe) without storing sensitive financial information.</li>
      </ul>

      <h2 id="section3">Legal Basis for Processing Personal Data (GDPR Compliance)</h2>
      <p>For users located in the European Union (EU) and European Economic Area (EEA), we process your data under the following lawful bases:</p>
      <ul>
        <li><strong>Consent</strong>: By signing up for our services, you consent to the processing of your personal data for the purposes outlined in this Privacy Policy.</li>
        <li><strong>Contract</strong>: We process personal data to fulfill our obligations under the terms and services you agree to when using the platform.</li>
        <li><strong>Legitimate Interest</strong>: In cases where we process data for fraud prevention, security, or platform improvement, we rely on our legitimate interest to protect users and provide high-quality services.</li>
      </ul>

      <h2 id="section4">Your Rights Under GDPR and Other Privacy Laws</h2>
      <p>We are committed to ensuring your rights under privacy laws are respected. Depending on your location, you may have the following rights:</p>
      <ul>
        <li><strong>Right to Access</strong>: You can request access to any personal data we have about you.</li>
        <li><strong>Right to Rectification</strong>: You have the right to correct any inaccurate data.</li>
        <li><strong>Right to Deletion</strong>: You can request that your personal data be deleted. We will comply unless retention is legally required (e.g., for tax purposes).</li>
        <li><strong>Right to Restrict Processing</strong>: You may request that we limit the processing of your personal data.</li>
        <li><strong>Right to Data Portability</strong>: You can request a copy of your data in a machine-readable format.</li>
        <li><strong>Right to Object</strong>: You may object to the processing of your data if it affects your rights or freedom.</li>
        <li><strong>Right to Withdraw Consent</strong>: You may withdraw your consent at any time.</li>
      </ul>
      <p>To exercise these rights, please contact us at [support email].</p>

      <h2 id="section5">California Residents (CCPA)</h2>
      <p>For users located in California, you have the following additional rights under the California Consumer Privacy Act (CCPA):</p>
      <ul>
        <li><strong>Right to Know</strong>: You have the right to know what personal information we collect, use, and share.</li>
        <li><strong>Right to Delete</strong>: You can request deletion of personal data we have collected about you.</li>
        <li><strong>Right to Opt-Out of Sale</strong>: As we do not sell your personal data, this provision does not apply to our platform.</li>
      </ul>
      <p>If you wish to exercise your CCPA rights, please contact us at [support email].</p>

      <h2 id="section6">International Transfers</h2>
      <p>If you are located outside of the United States, your personal data may be transferred and processed in countries other than your own. We take all necessary steps to ensure your data is treated securely and in accordance with this Privacy Policy.</p>

      <h2 id="section7">Data Security</h2>
      <p>We use industry-standard security measures to protect your data from unauthorized access, loss, or alteration. All communication with our platform is encrypted via SSL/TLS protocols. While we take all reasonable precautions, no system is 100% secure, and we cannot guarantee absolute security.</p>

      <h2 id="section8">Third-Party Services</h2>
      <p>Our platform may include links to third-party services (e.g., payment processors like Stripe). These services have their own privacy policies, and we are not responsible for their content or practices. We encourage you to review their policies before using these services.</p>

      <h2 id="section9">Data Retention</h2>
      <p>We retain your personal data only for as long as necessary to provide our services or comply with legal obligations. If you wish to have your data deleted, please contact us at [support email].</p>

      <h2 id="section10">Changes to This Policy</h2>
      <p>We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. Any changes will be posted on this page, and if significant, we will notify you via email.</p>

      <h2 id="section11">Contact Us</h2>
      <p>If you have any questions or concerns about this Privacy Policy or wish to exercise your rights, please contact us at:</p>
      <ul>
        <li>Email: info@infieldconnect.co</li>
        <li>Phone: +1 (415)-617-9498</li>
      </ul>
    </div>
  );
}

export default PrivacyPolicy;
