import ContactForm from "../components/ContactForm"

function ContactPage() {
    return(
        <div>
            <ContactForm></ContactForm>
        </div>
    );
}

export default ContactPage;